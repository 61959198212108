<template>
  <div>
    <el-dialog
      :title="titleParams.title"
      center
      width="400px"
      :visible.sync="dialogTimeViseble"
      @close="closeDialog"
      :close-on-click-modal="false"
      custom-class="add_class"
    >
      <div class="dialog-content">
        <el-form
          :model="courseForm"
          status-icon
          :rules="coursePopupRules"
          ref="courseForm"
          label-width="100px"
          size="small"
        >
          <el-col :span="20">
            <el-form-item label="学科" prop="subjectId">
              <el-select
                v-model="courseForm.subjectId"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in subjectIdList"
                  :key="item.macroId"
                  :label="item.name"
                  :value="item.macroId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="课程标题" prop="title">
              <el-input
                v-model="courseForm.title"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="上课时间" prop="beginTime">
              <el-date-picker
                v-model="courseForm.beginTime"
                type="datetime"
                :picker-options="beforePickerOptions"
                placeholder="选择日期"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="课时数" prop="usedClassHour">
              <el-input-number
                v-model="courseForm.usedClassHour"
                type="number"
                placeholder="请输入内容"
                :max="Number(4)"
                :min="Number(1)"
                @change="classHourChange"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="讲师" prop="teacherId">
              <el-input
                @click.native="selectTeacher()"
                :disabled="true"
                readonly
                v-model="teacherModel"
                placeholder="请输入内容"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="20">
            <el-form-item label="视频模式" prop="videoMode">
              <el-select
                v-model="courseForm.videoMode"
                placeholder="请选择"
                size="small"
                @change="videoModeChanged"
              >
                <el-option
                  v-for="item in videoModeList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="20">
            <el-form-item label="首次课设置" prop="isFirstClass">
              <el-radio-group v-model="courseForm.isFirstClass">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="监课展示" prop="isPrisonClass">
              <el-radio-group v-model="courseForm.isPrisonClass">
                <el-radio label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="回放录制" prop="isPlayback">
              <el-radio-group v-model="courseForm.isPlayback">
                <el-radio label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <div class="price_tip" v-show="this.dataParams.productType != 4">
        <div class="class_fee">
          本堂课流量费用：<span>{{ courseForm.expense }}</span
          >元
        </div>
        <div class="account_fee">账户余额：{{ organTotalBalance }}元</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="saveCourse('courseForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <p v-show="centerType == 'student'" style="padding: 7px 0">
        该时间段"{{ centerStudentName }}"学生已有课程安排
      </p>
      <p v-show="centerType == 'teacher'" style="padding: 7px 0">
        该时间段"{{ centerTeacherName }}"老师已有课程安排
      </p>
      <p style="padding: 7px 0">上课时间：{{ centerBeginTime }}</p>
      <p style="padding: 7px 0" v-show="centerTrue">
        机构：{{ centerOrganName }}
      </p>
      <p style="padding: 7px 0">上课时长：{{ centerUsedClassHour }}小时</p>
      <p style="padding: 7px 0">辅导老师：{{ centerTeacherName }}老师</p>
      <p style="padding: 7px 0">上课学生：{{ centerStudentName }}学生</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  constellation,
  teachStyle,
  teachYear,
  teachSpeed,
  achievement,
} from "@/api/public";
import { saveOneToMore } from "@/api/administration/tubeTeachBusiness/studentManage.js";
import {
  productList, //公开课列表
  configteacherPlanList,
  saveLiveBroadcastClass, //公开课排课保存`
  findCourseItem, //公开课排课列表
  deleteLiveBroadcastClass, //公开课删除
} from "@/api/operates/productManage";
import dayjs from "dayjs";
import {
  checkTeacherClassTime,
  checkTeacherOrganClassTime,
  checkStudentClassTime,
  xmtProductItemInfo,
  xmtProductItemDelete,
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import { getFlowFeeByMode, getOrganBalance } from "@/api/system/systemManage";
export default {
  props: ["timeStatus", "dataParams", "tableRadioInfo"],
  watch: {
    timeStatus(newValue, oldValue) {
      this.dialogTimeViseble = newValue;
      this.oneToMoreClassNum = this.dataParams.oneToMoreClassNum;
      if (newValue == true) {
        if (this.dataParams.type == "edit") {
          this.titleParams = {
            title: "编辑课程",
            type: "edit",
          };
          this.xmtProductItemInfoFn();
        } else {
          this.titleParams = {
            title: "新增课程",
            type: "add",
          };
          this.getSingleClassFee();
        }
        this.teacherModel = "";
        this.courseForm.productName = this.dataParams.productName;
        this.courseForm.productId = this.dataParams.id;
        this.courseForm.sectionId = this.dataParams.gkkPeriod;
        this.getOrganBalance();
      }
    },
    tableRadioInfo() {
      this.courseForm.configteacherPlanId = this.tableRadioInfo.id;
      this.courseForm.teacherId = this.tableRadioInfo.teacherId;
      this.teacherModel = this.tableRadioInfo.teacherRealname;
    },
  },
  created() {
    this.getSubjectList();
  },
  data() {
    return {
      infos: this.tableRadioInfo,
      teacherModel: "",
      dialogTimeViseble: false,
      subjectIdList: [],
      lastTime: 0,
      titleParams: {
        title: "新增课程",
        type: "add",
      },
      videoModeList: [
        /* {
          name: "普通模式",
          type: 1,
        }, */
        {
          name: "高级模式",
          type: 2,
        },
      ],
      oneToMoreClassNum: 1,
      oneToMoeSingleClassPrice: 0, // 一对多单价
      organTotalBalance: "--", // 机构剩余金额
      courseForm: {
        beginTime: "",
        classHourType: 6, //公开课直播排课
        classMode: "1",
        configteacherPlanId: "",
        isFirstClass: "0",
        isPlayback: "1",
        isPrisonClass: "1",
        productId: "",
        sectionId: "",
        subjectId: "",
        teacherId: "",
        title: "",
        usePrice: 0,
        usedClassHour: "",
        productName: "",
        videoMode: 2,
        expense: "--",
      },
      beforePickerOptions: {
        // 限制时间
        selectableRange: ["06:00:00 -23:59:59"],
        // 限制日期
        disabledDate: this.disabledDateTime,
      },
      coursePopupRules: {
        subjectId: [
          { required: true, message: "学科不能为空", trigger: "blur" },
        ],
        title: [
          { required: true, message: "课程标题不能为空", trigger: "blur" },
        ],
        beginTime: [
          { required: true, message: "上课时间不能为空", trigger: "blur" },
        ],
        teacherId: [
          { required: true, message: "讲师不能为空", trigger: "change" },
        ],
        usedClassHour: [
          { required: true, message: "课时数不能为空", trigger: "blur" },
        ],
      },
      centerType: "",
      centerStudentName: "",
      centerTeacherName: "",
      centerBeginTime: "",
      centerTitle: "",
      centerUsedClassHour: "",
      centerOrganName: "",
      centerTrue: false,
      centerDialogVisible: false,
      editUsedClassHour: "",
      editTime: "",
    };
  },
  methods: {
    xmtProductItemInfoFn() {
      xmtProductItemInfo(this.dataParams.classId).then((res) => {
        if (res.code == 0) {
          var resData = res.rows;
          this.teacherModel = resData.teacherRealName;
          this.editUsedClassHour = resData.usedClassHour;
          this.editTime = resData.beginTime;
          if (resData.classHourType == 6) {
            this.courseForm = {
              beginTime: resData.beginTime,
              classHourType: 6, //公开课直播排课
              classMode: "1",
              configteacherPlanId: resData.configteacherPlanId,
              isFirstClass: "0",
              isPlayback: "1",
              isPrisonClass: "1",
              productId: resData.productId,
              sectionId: resData.sectionId,
              subjectId: resData.subjectId,
              teacherId: resData.teacherId,
              title: resData.title,
              usePrice: 0,
              id: resData.id,
              usedClassHour: resData.usedClassHour,
              productName: resData.productName,
              videoMode: 2,
              expense: "--",
            };
          } else {
            this.courseForm = {
              id: resData.id,
              subjectId: resData.subjectId,
              title: resData.title,
              beginTime: resData.beginTime,
              usedClassHour: resData.usedClassHour,
              isFirstClass: String(resData.isFirstClass),
              isPrisonClass: String(resData.isPrisonClass),
              isPlayback: String(resData.isPlayback),
              teacherId: resData.teacherId,
              classroomId: resData.classroomId,
              teachFee: resData.teachFee,
              configteacherPlanId: resData.configteacherPlanId,
              videoMode: 2,
              expense: "--",
            };
          }
          this.getSingleClassFee();
        }
      });
    },
    disabledDateTime(time) {
      return time.getTime() < Date.now() - 8.64e7;
    },
    /* 视频模式改变 */
    videoModeChanged() {
      this.getSingleClassFee();
    },
    /* 课时数变化 */
    classHourChange() {
      this.computeClassExpense();
    },
    /* 获取当前机构余额 */
    getOrganBalance() {
      if (this.dataParams.productType == 4) {
        return;
      }
      this.organTotalBalance = "--";
      getOrganBalance().then((res) => {
        if (res.flag == "error") {
          return this.$message.error(res.msg);
        }
        this.organTotalBalance = res.rows.accountBalance
          ? res.rows.accountBalance
          : 0;
      });
    },
    /* 获取特定上课模式下单节课的费用 */
    getSingleClassFee() {
      if (this.dataParams.productType == 4) {
        return;
      }
      this.oneToMoeSingleClassPrice = 0;
      this.courseForm.expense = "--";
      const classMode = 2;
      const { videoMode } = this.courseForm;
      if (videoMode) {
        getFlowFeeByMode({
          classMode,
          videoMode,
        }).then((res) => {
          console.log("getOrganBalance: ", res);
          if (res.flag == "error") {
            return this.$message.error(res.msg);
          }
          if (res.rows.sysFlowFeeMoreList) {
            res.rows.sysFlowFeeMoreList.forEach((item) => {
              if (
                item.startPeople <= this.oneToMoreClassNum &&
                this.oneToMoreClassNum <= item.endPeople
              ) {
                this.oneToMoeSingleClassPrice = item.flowFeeMore;
              }
            });
            if (!this.oneToMoeSingleClassPrice) {
              this.$message.warning(
                "未找到对应的一对多费用，请先进行费用设置！"
              );
            }
          }
          this.computeClassExpense();
        });
      }
    },
    /* 计算本次排课消耗金额 */
    computeClassExpense() {
      if (this.dataParams.productType == 4) {
        return;
      }
      let classPirce = -1;
      const { usedClassHour } = this.courseForm;
      if (this.oneToMoeSingleClassPrice) {
        classPirce = this.oneToMoeSingleClassPrice * usedClassHour;
      }
      if (classPirce == -1) {
        this.courseForm.expense = "--";
      } else {
        this.courseForm.expense = classPirce;
      }
    },
    saveCourse(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dataParams.productType != 4) {
            if (this.courseForm.expense == "--") {
              return this.$message.warning("流量费用不正确！");
            }
            if (this.organTotalBalance < this.courseForm.expense) {
              return this.$message.warning("流量费用不能大于账户余额！");
            }
          }

          if (
            dayjs(this.courseForm.beginTime)
              .add(1, "minute")
              .isBefore(dayjs()) &&
            this.courseForm.classMode == "1"
          ) {
            this.msgWarn("排课不能再到当前时间之前的时间！请重新选择时间");
            return;
          }
          let now = new Date().valueOf();
          if (this.lastTime == 0 || now - this.lastTime > 2000) {
            this.lastTime = now;
            if(this._saveFlag){
              return
            }
            this._saveFlag = true
            if (this.titleParams.type == "add") {
              // if(this.dataParams.productType==5){

              // }
              checkTeacherClassTime({ ...this.courseForm, expense: null }).then((res) => {
                if (res.msg == "操作成功！") {
                  checkTeacherOrganClassTime({ ...this.courseForm,expense: null }).then((res) => {
                    if (res.msg == "操作成功！") {
                      saveLiveBroadcastClass({ ...this.courseForm, expense: null }).then((res) => {
                        this._saveFlag = false
                        if (res.code == 0 && res.msg == "操作成功！") {
                          this.msgSuccess("添加成功！");
                          this.$emit("CB_timeStatus");
                          this.$refs["courseForm"].resetFields();
                        } else {
                          this.msgWarn(res.rows);
                          return false;
                        }
                      }).catch(err => {
                        this._saveFlag = false
                      });
                    } else {
                      this._saveFlag = false
                      //机构占用
                      this.centerType = "teacher";
                      this.centerStudentName = res.rows.studentName;
                      this.centerTeacherName = res.rows.teacherRealName;
                      this.centerBeginTime = res.rows.beginTime;
                      this.centerTitle = res.rows.title;
                      this.centerUsedClassHour = res.rows.usedClassHour;
                      this.centerOrganName = res.rows.organName;
                      this.centerTrue = true;
                      this.centerDialogVisible = true;
                    }
                  }).catch(err => {
                    this._saveFlag = false
                  });
                } else {
                  this._saveFlag = false
                  //老师被占用
                  this.centerType = "teacher";
                  this.centerStudentName = res.rows.studentName;
                  this.centerTeacherName = res.rows.teacherRealName;
                  this.centerBeginTime = res.rows.beginTime;
                  this.centerTitle = res.rows.title;
                  this.centerUsedClassHour = res.rows.usedClassHour;
                  this.centerTrue = false;
                  this.centerDialogVisible = true;
                }
              }).catch(err => {
                this._saveFlag = false
              });
            } else if (this.titleParams.type == "edit") {
              if (this.dataParams.productType == 4) {
                //公开课
                if (
                  this.editTime == this.courseForm.beginTime &&
                  this.editUsedClassHour == this.courseForm.usedClassHour
                ) {
                  deleteLiveBroadcastClass({
                    id: this.dataParams.classId,
                  }).then((res) => {
                    delete this.courseForm.id;
                    saveLiveBroadcastClass({ ...this.courseForm, expense: null }).then((res) => {
                      this._saveFlag = false
                      if (res.code == 0 && res.msg == "操作成功！") {
                        this.msgSuccess("添加成功！");
                        this.$emit("CB_timeStatus");
                        this.$refs["courseForm"].resetFields();
                      } else {
                        this.msgWarn(res.rows);
                        return false;
                      }
                    }).catch(err => {
                      this._saveFlag = false
                    });
                  }).catch(err => {
                    this._saveFlag = false
                  });
                } else {
                  checkTeacherClassTime({ ...this.courseForm, expense: null }).then((res) => {
                    if (res.msg == "操作成功！") {
                      checkTeacherOrganClassTime({ ...this.courseForm, expense: null }).then(
                        (res) => {
                          if (res.msg == "操作成功！") {
                            deleteLiveBroadcastClass({
                              id: this.dataParams.classId,
                            }).then((res) => {
                              delete this.courseForm.id;
                              saveLiveBroadcastClass({ ...this.courseForm, expense: null }).then(
                                (res) => {
                                  this._saveFlag = false
                                  if (
                                    res.code == 0 &&
                                    res.msg == "操作成功！"
                                  ) {
                                    this.msgSuccess("添加成功！");
                                    this.$emit("CB_timeStatus");
                                    this.$refs["courseForm"].resetFields();
                                  } else {
                                    this.msgWarn(res.rows);
                                    return false;
                                  }
                                }
                              ).catch(err => {
                                this._saveFlag = false
                              });
                            }).catch(err => {
                              this._saveFlag = false
                            });
                          } else {
                            this._saveFlag = false
                            //机构占用
                            this.centerType = "teacher";
                            this.centerStudentName = res.rows.studentName;
                            this.centerTeacherName = res.rows.teacherRealName;
                            this.centerBeginTime = res.rows.beginTime;
                            this.centerTitle = res.rows.title;
                            this.centerUsedClassHour = res.rows.usedClassHour;
                            this.centerOrganName = res.rows.organName;
                            this.centerTrue = true;
                            this.centerDialogVisible = true;
                          }
                        }
                      ).catch(err => {
                        this._saveFlag = false
                      });
                    } else {
                      this._saveFlag = false
                      //老师被占用
                      this.centerType = "teacher";
                      this.centerStudentName = res.rows.studentName;
                      this.centerTeacherName = res.rows.teacherRealName;
                      this.centerBeginTime = res.rows.beginTime;
                      this.centerTitle = res.rows.title;
                      this.centerUsedClassHour = res.rows.usedClassHour;
                      this.centerTrue = false;
                      this.centerDialogVisible = true;
                    }
                  }).catch(err => {
                    this._saveFlag = false
                  });
                }
              } else {
                //一对多
                this.courseForm.classHourType = "1";
                xmtProductItemDelete(this.dataParams.classId).then((res) => {
                  checkTeacherClassTime(this.courseForm).then((res) => {
                    if (res.msg == "操作成功！") {
                      checkStudentClassTime(this.courseForm).then((res) => {
                        if (res.msg == "操作成功！") {
                          this.courseForm.classroomId =
                            this.dataParams.classroomId;
                          saveOneToMore(this.courseForm).then((res) => {
                            this._saveFlag = false
                            if (res.code == 0 && res.msg == "操作成功！") {
                              this.msgSuccess("操作成功！");
                              this.$emit("CB_timeStatus");
                              this.$refs["courseForm"].resetFields();
                            } else {
                              this.msgWarn(res.msg);
                              return;
                            }
                          }).catch(err => {
                            this._saveFlag = false
                          });
                        } else {
                          this._saveFlag = false
                          //学生被占用
                          this.centerType = "student";
                          this.centerStudentName = res.rows.studentName;
                          this.centerTeacherName = res.rows.teacherName;
                          this.centerBeginTime = res.rows.beginTime;
                          this.centerTitle = res.rows.title;
                          this.centerUsedClassHour = res.rows.usedClassHour;
                          this.centerDialogVisible = true;
                        }
                      }).catch(err => {
                        this._saveFlag = false
                      });
                    } else {
                      //老师被占用
                      this._saveFlag = false
                      this.centerType = "teacher";
                      this.centerStudentName = res.rows.studentName;
                      this.centerTeacherName = res.rows.teacherName;
                      this.centerBeginTime = res.rows.beginTime;
                      this.centerTitle = res.rows.title;
                      this.centerUsedClassHour = res.rows.usedClassHour;
                      this.centerDialogVisible = true;
                    }
                  }).catch(err => {
                    this._saveFlag = false
                  });
                }).catch(err => {
                  this._saveFlag = false
                });
              }
            }
          } else {
            console.log("不触发");
          }
        }
      });
    },
    getSubjectList() {
      getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.subjectIdList = res.rows;
        }
      });
    },
    //配置讲师
    selectTeacher() {
      if (!this.courseForm.subjectId) {
        this.msgWarn("请先选择学科！");
        return false;
      }
      this.$emit("callBackInfo", {
        subjectId: this.courseForm.subjectId,
        productId: this.courseForm.productId,
      });
    },
    closeDialog() {
      this.$emit("CB_timeStatus");
      this.$refs["courseForm"].resetFields();
    },
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .classInfo_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .classInfo_ul li {
    width: 25%;
    margin-top: 20px;
    font-size: 16px;
  }
  .subject_ul {
    width: 100%;
    overflow: visible;
    height: 55px;
    line-height: 55px;
  }
  .subject_ul li {
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    float: left;
  }
}
.subjectActive {
  overflow: hidden;
}
.teachContent {
  display: flex;
  flex-direction: column;
}
.infoDetails {
  height: 150px;
  padding-top: 20px;
  display: flex;
}
.infoDetails .left {
  display: flex;
  width: 35%;
  min-width: 500px;
}
.infoDetails .content {
  width: 350px;
}
.infoDetails .content ul {
  margin-top: 30px;
}
.infoDetails .content ul li {
  margin-top: 5px;
}
.infoDetails .content ul span:nth-child(1) {
  font-size: 15px;
  color: #414a60;
}
.infoDetails .content ul span:nth-child(2) {
  font-size: 15px;
  color: #61687c;
}
.infoDetails .right {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
}
/deep/ .add_class .el-dialog__body {
  padding-bottom: 10px;
}
.price_tip {
  margin-top: 10px;
  display: flex;
  line-height: 24px;
  padding-left: 20px;
  .account_fee {
    margin-left: 20px;
  }
}
</style>