<template>
    <div>
        <el-dialog
            title="作业"
            center
            width="900px"
            :visible.sync="taskViseble"
            :close-on-click-modal="false"
            @close="closeDialog"
        >
            <div class="dialog-content">
                <div class="classTitle">
                    <p>班级信息</p>
                    <ul>
                        <li><span>班级名称：</span><span>5dshud玛珥湖好</span></li>
                        <li><span>产品名称：</span><span>212</span></li>
                        <li><span>学段：</span><span>212</span></li>
                        <li><span>满班人数：</span><span>212吃</span></li>
                    </ul>
                </div>
                <div class="table-area" style="margin-top:20px;">
                        <el-table
                        size="small"
                        :data="dataList"
                        style="width: 100%; margin-bottom: 20px"
                        row-key="userId"
                        height="100%"
                        border
                        tooltip-effect="dark"
                        >
                        <el-table-column
                            prop="title"
                            label="课程名称"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="title"
                            label="作业标题"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="title"
                            label="布置老师"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="teacherRealName"
                            label="上课时间"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="peopleUsedNum"
                            label="布置时间"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="beginTime"
                            label="未提交/已提交"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="usedClassHour"
                            label="未点评/已点评"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="seeInfo"
                                >查看</el-button
                            > 
                            <el-button
                                type="text"
                                size="small"
                                >删除</el-button
                            >
                            </template>
                        </el-table-column>
                        </el-table>
                </div>
            </div>
        </el-dialog>
        <el-dialog
            title="作业详情"
            center
            width="600px"
            :visible.sync="seeInfoViseble"
            :close-on-click-modal="false"
        >
            <div class="dialog-content">
                <div class="taskInfo">
                    <p><span>作业标题：212121</span></p>
                    <p><span>布置教师：21212</span><span>布置时间：212222</span></p>
                </div>
                <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="handleSelect"
                >
                    <el-menu-item index="1">未点评</el-menu-item>
                    <el-menu-item index="2">已点评</el-menu-item>
                </el-menu>
                <div class="taskDiv">
                    <div>
                        <div class="left"><img src="" alt=""></div>
                        <div class="right">
                            <div><p><span>学生姓名</span><span>张三</span></p></div>
                            <div>
                                <p>作业详情</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>

export default {
    props:["taskStatus"],
    watch:{
        taskStatus(newValue,oldValue){
            this.taskViseble=newValue;
        }
    },
    data(){
        return{
            activeIndex:"1",
            taskViseble:false,
            seeInfoViseble:false,
            handleKey:"",
            dataList:[{
                title:'123'
            }],

        }
    },
    methods:{
        handleSelect(key, keyPath){
            this.handleKey=key;
            if(key==1){

            }else if(key==2){

            }
        },
        //查看
        seeInfo(){
            this.seeInfoViseble=true;
        },
        closeDialog(){
            this.$emit('CB_taskStatus')
        }
    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .studentInfo{
    h2{
      font-size:16px;
      margin-top:20px;
    }
    ul{
      display: flex;
      flex-wrap:wrap;
      margin-top:10px;
      li{
        width:33.3%;
        padding:5px 0;
      }
    }
  }
  .infoRecord{
    display: flex;
    width:100%;
    span{
      width:25%;
      display: inline-block;
      padding:5px 0;
    }
  }
}
 .orderDetail{
    h2{
        font-size: 16px;
    }
    ul{
        display: flex;
        margin-top:20px;
        li{
            margin-right:40px;
            span:nth-child(1){
                color:#909399;
            }
            span:nth-child(2){
                color:#000000;
            }
        }
    }
    p{
        margin-top:10px;
        span:nth-child(1){
            color:#909399;
        }
        span:nth-child(2){
            color:#000000;
        }
    }
  }
  .subject_ul {
    width: 100%;
    overflow: visible;
    height: 55px;
    line-height: 55px;
  }
  .subject_ul li {
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    float: left;
  }
  .classTitle{
    p{
        font-size:16px;
        font-weight: bold;
    }
    ul{
        display: flex;
        margin-top:20px;
        li{
            display: flex;
            width: 25%;
            span:nth-child(1){
                color:#909399;
            }
            span:nth-child(2){
                color:#000;
            }
        }
    }
  }
  .taskInfo{
    p:nth-child(2){
        margin-top:15px;
        span:nth-child(2){
            margin-left:20px;
        }
    }
  }
  .taskDiv{

  }
</style>