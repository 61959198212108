<template>
    <div>
        <el-dialog
        title="皓之学在线"
        center
        width="400px"
        :close-on-click-modal="false"
        :visible="Visible"
        @close="$emit('close')"
    >
        <div>
          <dl class="info_dl" v-for="(item,index) in courseList" :key="index" @click="seeInfo()">
            <dt><img :src="photoSrc" alt=""><span>{{item.attendClassStatus==0?"未上课":item.attendClassStatus==1?"已上课":"上课中"}}</span></dt>
            <dd>
              <p>{{item.productName}}</p>
              <p><span><i class="el-icon-view" style="margin-right:4px;"></i>{{visitorsNumber}}</span><span><i class="el-icon-share" style="margin-right:4px;"></i>分享</span></p>
            </dd>
          </dl>
        </div>
        </el-dialog>
        <el-dialog
            title=""
            center
            width="400px"
            :close-on-click-modal="false"
            :visible.sync="infoVisible"
        >
          <div style="padding-bottom:30px;">
            <dl>
              <dt><img :src="imgSrc" alt="" v-show="!videoSrc" style="width:100%;"><video style="width:100%;" :src="videoSrc" controls="controls" v-show="videoSrc"></video></dt>
              <dd style="display:flex;justify-content: space-between;margin-top:10px;"><span style="font-size:16px;font-weight:700;color:#333;">{{videoSrc?videoTitle:productName}}</span><span>{{visitorsNumber}}人报名</span></dd>
            </dl>
            <p style="margin-top:30px;font-size:18px;font-weight:700;color:#333;">课程详情</p>
            <p v-html="introduce" style="margin-top:15px;">{{introduce}}</p>
          </div>
        </el-dialog>
    </div>
</template>
<script>
import {
  getSelectList,
  teachTitle,
  fileUpload,
  phaseCode,
  subjectCode
} from "@/api/public";
import {
  productInfo as productInfoDetail,
  findCourseItem
} from "@/api/operates/productManage";
export default {
    props:['Visible','visibleId','visibleType'],
    data(){
        return{
            infoVisible:false,
            dataList:[],
            phaseList:[],
            courseList:[],
            photoSrc:"",
            visitorsNumber:"",
            videoType:"",
            productName:"",
            xmtProductCourseEntity:[],
            videoSrc:"",
            videoTitle:"",
            imgSrc:"",
            introduce:""
        }
    },
    created(){
      this.getPhaseList();
      this.productInfoDetailFn();
      this.findCourseItemFn();
    },
    methods:{
      productInfoDetailFn(){
         productInfoDetail(this.visibleId).then(res=>{
            if(res.code==0){
              this.videoType=res.rows.videoType;
              this.photoSrc=res.rows.photo;
              this.visitorsNumber=res.rows.visitorsNumber;
              this.productName=res.rows.productName;
              this.xmtProductCourseEntity=res.rows.xmtProductCourseEntity;
              this.introduce=res.rows.introduce;
            }
         })
      },
      findCourseItemFn(){
          findCourseItem({productId:this.visibleId}).then((res) => {
                if(res.code==0){
                  if(this.videoType==1){//直播课 没视频
                    this.courseList=res.rows.listProductItemTeachManager;
                  }else{
                    this.courseList=[{
                      productName:this.productName
                    }]
                  }
                }
              })
        },
        seeInfo(){
          this.infoVisible=true;
          if(this.xmtProductCourseEntity.length){
              this.videoSrc=this.xmtProductCourseEntity[0].vedioAddress;
              this.videoTitle=this.xmtProductCourseEntity[0].vedioName;

          }else{
            this.imgSrc=this.photoSrc;
          }
        },
      getPhaseList() {
      getSelectList({
        parentCode: phaseCode,
      }).then((res) => {
        if (res.code == 0) {
          this.phaseList = res.rows;
          this.phaseList.unshift({
              macroId: "",
              name:"全部"
          });
        }
      });
    }
    }
}
</script>
<style scoped lang="scss">
    .stuInfo{
        display: flex;
        font-size:16px;
        span:nth-child(2){
            margin-left:60px;
        }
    }
    .seePrice-div{
      font-size:15px;
      padding-bottom:30px;
      ul{
        margin-top:20px;
        li{
          line-height: 26px;
        }
      }
    }
    .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-contents{
    display: flex;
    flex-direction: column;
    height:100%;
    .btn-div{
      height:30px;
      float:right;
      margin-top:20px;
    }
    .table-area{
      flex:1;
      overflow: hidden;
    }
  }
  .nav_ul{
    display: flex;
    align-items: center;
    margin:5px 0;
    height: 40px;
    li{
      margin-right:10px;
      span{
        padding:7px 15px;
        font-size:12px;
        border-radius: 3px;
        color:#fff;
        border:1px solid #1890ff;
        background: #1890ff;
        cursor:pointer;
      }
    }
  }
  .info_dl{
    img{
      height:200px;
      width: 100%;
    }
    dt{
      position: relative;
      cursor:pointer;
      span{
        position: absolute;
        left:6px;
        top:6px;
        color:#fff;
        background: orange;
        padding:5px 10px;
        border-radius: 3px;
        font-size: 12px;;
      }
    }
    dd{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding:0 10px;
      height:50px;
      p:nth-child(1){
        color:#333;
        font-size:16px;
        font-weight: 600;
      }
      p:nth-child(2){
        span:nth-child(1){
          margin-right:10px;
        }
      }
    }
  }
  
</style>