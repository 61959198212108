<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="请输入产品编号/名称"
            size="small"
            style="min-width:120px"
             @click="()=>(listParams.pageNumber=1,getProductList())"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="listParams.sectionId"
            placeholder="请选择"
            size="small"
            @click="()=>(listParams.pageNumber=1,getProductList())"
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">产品状态：</span>
          <el-select
            v-model="listParams.productStatus"
            placeholder="请选择"
            size="small"
            @click="()=>(listParams.pageNumber=1,getProductList())"
          >
            <el-option
              v-for="item in productStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="()=>(listParams.pageNumber=1,getProductList())"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="dealAdd">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>添加</span>
          </el-button>
          <el-button type="default" size="small" @click="dealEdit">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="dealDelete">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="productList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="productListTable"
        @selection-change="handleSelectionChange"
        @row-click="studentItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="no"
          label="产品编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="添加时间"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="dealProductStatus"
          label="产品状态"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
             <el-button
              type="text"
              size="small"
              @click.stop="daalSee(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="scope.row.productStatus == 1"
              @click.stop="daalPublish(scope.row)"
              >发布</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="scope.row.productStatus == 3"
              @click.stop="daalPublish(scope.row)"
              >重新发布</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="scope.row.productStatus == 2"
              @click.stop="daalSoldOut(scope.row)"
              >下架</el-button
            >
            <el-button
              type="text"
              size="small"
              @click.stop="daalSuggest(scope.row)"
            >
              <span v-show="scope.row.suggest == 0">置顶</span>
              <span v-show="scope.row.suggest == 1">取消置顶</span>
            </el-button>
            <!-- <el-button
              type="text"
              size="small"
              @click.stop="toogleExpand(scope.row)"
            >
             查看详情
            </el-button> -->
          </template>
        </el-table-column>
        <!-- <el-table-column type="expand" width="1">
          详情数据
22      </el-table-column> -->
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :visible.sync="saveDialogVisible"
      :close-on-click-modal="false"
      width="900px"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="120px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="产品封面" prop="photo" ref="image">
                <el-upload
                  class="avatar-uploader"
                  :action="fileUpload"
                  accept="image/jpeg, image/png/, image/gif"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="saveRuleForm.photo"
                    :src="saveRuleForm.photo"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <span>建议图片尺寸:650*480</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="产品名称" prop="productName">
                <el-input v-model="saveRuleForm.productName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div
            v-for="(item, key) in saveRuleForm.xmtProductPackageEntityList"
            :key="key"
          >
            <div class="line"></div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="年级"
                  :prop="'xmtProductPackageEntityList.' + key + '.sectionId'"
                  :rules="saveRules.sectionId"
                >
                  <el-select v-model="item.sectionId" placeholder="请选择">
                    <el-option
                      v-for="option in sectionList_clean"
                      :key="option.macroId"
                      :label="option.name"
                      :value="option.macroId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="课时数（课时）"
                  :prop="'xmtProductPackageEntityList.' + key + '.classHour'"
                  :rules="saveRules.classHour"
                >
                  <el-input v-model.number="item.classHour"  @blur="zdySinglePriceBlur(item.classHour,item.singlePrice,key)"></el-input>
                </el-form-item>
              </el-col>
                <!-- <el-col :span="12">
                <el-form-item
                  label="学科"
                  :prop="'xmtProductPackageEntityList.' + key + '.subjectId'"
                  :rules="saveRules.subjectId"
                >
                  <el-select v-model="item.subjectId" placeholder="请选择">
                    <el-option
                      v-for="option in subjectList"
                      v-show="option.macroId != 0"
                      :key="option.macroId"
                      :label="option.name"
                      :value="option.macroId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
            </el-row>
            <el-row :gutter="20">
              <!-- <el-col :span="12">
                <el-form-item
                  label="课时数（课时）"
                  :prop="'xmtProductPackageEntityList.' + key + '.classHour'"
                  :rules="saveRules.classHour"
                >
                  <el-input v-model.number="item.classHour"  @blur="zdySinglePriceBlur(item.classHour,item.singlePrice,key)"></el-input>
                </el-form-item>
              </el-col> -->
              <el-col :span="12">
                <el-form-item
                  label="单价（元）"
                  :prop="'xmtProductPackageEntityList.' + key + '.singlePrice'"
                  :rules="saveRules.singlePrice"
                >
                  <el-input v-model.number="item.singlePrice" @blur="zdySinglePriceBlur(item.classHour,item.singlePrice,key)"></el-input>
                </el-form-item>
              </el-col>
               <el-col :span="12">
                <el-form-item
                  label="总价（元）"
                  :prop="'xmtProductPackageEntityList.' + key + '.totalPrice'"
                  :rules="saveRules.totalPrice"
                >
                  <el-input v-model.number="item.totalPrice"></el-input>
                </el-form-item>
              </el-col>
            </el-row>  
            <el-row :gutter="20">
               <el-col :span="12">
                <el-form-item
                  label="教师职称"
                  :prop="
                    'xmtProductPackageEntityList.' + key + '.teacherTitleId'
                  "
                  :rules="saveRules.teacherTitleId"
                >
                  <el-select v-model="item.teacherTitleId" placeholder="请选择">
                    <el-option
                      v-for="option in teachTitleList"
                      :key="option.macroId"
                      :label="option.name"
                      :value="option.macroId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12" class="center">
                <el-button
                  type="primary"
                  size="small"
                  @click="dealAddCourseBag"
                  v-show="
                    key == saveRuleForm.xmtProductPackageEntityList.length - 1
                  "
                  >添加</el-button
                >
              </el-col>
              <el-col :span="12" class="center">
                <el-button
                  type="danger"
                  size="small"
                  @click="dealDeleteCourseBag(key)"
                  v-show="saveRuleForm.xmtProductPackageEntityList.length != 1"
                  >删除</el-button
                >
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelProduct()" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave('saveRuleForm')"
          size="small"
          :disabled="saveDisabled"
          >确 定</el-button
        >
      </span>
    </el-dialog>
     <el-dialog
      title="产品详情"
      :visible.sync="courseDetailSync"
      width="800px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
           <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
              <el-col :span="8">
              <span>产品封面：</span>
               <el-image
                    style="width: 100px; height: 100px"
                    :src="courseDetailParams.photo"
                    v-if="courseDetailParams.photo"
                      :preview-src-list="srcList"
                  >
                  </el-image>
            </el-col>
            <el-col :span="8">
              <span>产品名称：</span>
              <span>{{ courseDetailParams.productName }}</span>
            </el-col>
            <el-col :span="8">
              <span>购买人数：</span>
              <span>{{ courseDetailParams.peopleCountOTO }}人</span>
            </el-col>
          </el-row>
          <ul class="orderList">
            <li v-for="(item, index) in entityList" :key="index">
              <span>年级:{{item.sectionName}}</span>
              <span>总课时：{{item.classHour}}课时</span>
              <span>课时单价:{{item.singlePrice}}元</span>
              <span>总金额:{{item.totalPrice}}元</span>
              <span>老师职称:{{item.teacherTitleName}}</span>
            </li>
          </ul>
      </div>
     </el-dialog>
  </div>
</template>
<script>
import {
  productList,
  productRemove,
  productAdd,
  productUpdate,
  productInfoDetail,
  productUpdateCourse,
} from "@/api/operates/productManage";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
} from "@/api/public";
let id = 0;
export default {
  inject: ["reload"],
  data() {
    return {
      saveDisabled:false,
      entityList:[],
      fileUpload,
      productList: [],
      listParams: {
        name: "",
        sectionId: "0",
        subjectId: "0",
        productStatus: "0",
        productType: 1,
        pageNumber: 1,
        pageSize: 10,
      },
      productStatusList: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "已保存未发布",
        },
        {
          value: "2",
          label: "已发布",
        },
        {
          value: "3",
          label: "已下架",
        },
      ],
      total: 0,
      saveDialogVisible: false,
      saveRuleForm: {
        photo: "",
        productName: "",
      
        productType: '1',
        delFlag: '1',
        xmtProductPackageEntityList: [
          {
            sectionId: "",
            classHour: "",
            teacherTitleId: "",
            totalPrice: "",
            singlePrice: "",
          },
        ],
      },
      teachTitleList: [],
      srcList:[],
      saveRules: {
        photo: [
          { required: true, message: "产品封面不能为空", trigger: "blur" },
        ],
        productName: [
          { required: true, message: "产品名称不能为空", trigger: "blur" },
        ],
        sectionId: [
          { required: true, message: "年级不能为空", trigger: "change" },
        ],
         subjectId: [
          { required: true, message: "学科不能为空", trigger: "blur" },
        ],
        classHour: [
          { required: true, message: "总课时不能为空", trigger: "blur" },
          { type: 'number', message: '总课时必须为数字', trigger: "blur"},
        ],
        teacherTitleId: [
          { required: true, message: "教师职称不能为空", trigger: "change" },
        ],
        totalPrice: [
          { required: true, message: "课时总价不能为空", trigger: "blur" },
          { type: 'number', message: '总价必须为数字', trigger: "blur"},
        ],
        singlePrice: [
          { required: true, message: "课时单价不能为空", trigger: "blur" },
          { type: 'number', message: '单价必须为数字', trigger: "blur"},
        ],
      },
      selectProductList: [],
      sectionList: [
        {
          macroId: "0",
          name: "全部",
        },
      ],
      sectionList_clean: [],
      saveDialog: {
        title: "新增产品",
        type: "add",
      },
      subjectList: [
        {
          macroId: "0",
          name: "全部",
        },
      ],
      courseDetailSync:false,
      courseDetailParams:{
          photo:'',
          productName:'',

      }

    };
  },
   watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    },
   
  },
  methods: {
   
    dealProductStatus(row) {
      let status = "";
      for (let i = 0, len = this.productStatusList.length; i < len; i++) {
        if (row.productStatus == this.productStatusList[i].value) {
          status = this.productStatusList[i].label;
        }
      }
      return status;
    },
    handleAvatarSuccess(res, file) {
      this.saveRuleForm.photo = res[0].url;
        this.$refs.image.clearValidate();
    },
    beforeAvatarUpload(file) {
      let reg = /\.(png|jpg|gif|jpeg)$/;
      if (!reg.test(file.name)) {
        this.msgError(
          "支持上传的图片格式为png，jpg，gif，jpeg；请检查格式是否正确！"
        );
      }
      return reg.test(file.name);
    },
   
    dealAddCourseBag(){
      this.saveRuleForm.xmtProductPackageEntityList.push({
        sectionId: "",
        classHour: "",
        teacherTitleId: "",
        totalPrice: "",
        singlePrice: "",
      })
    },

    dealDeleteCourseBag(index){
      this.saveRuleForm.xmtProductPackageEntityList.splice(index, 1)
    },
    daalSuggest(row) {
      this.$confirm(`${row.suggest == "0" ? "确定要置顶吗?" : "确定要取消置顶吗?"}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        productUpdateCourse({
        id: row.id,
       suggest: row.suggest == "0" ? 1 : 0,
      })
          .then(res => {
            if (res.code == 0) {
            this.msgSuccess(
            `${row.suggest == "0" ? "置顶成功！" : "取消置顶成功！"}`
          );
          this.getProductList();
            }
          })
          .catch(() => {});
      });
    },
    daalPublish(row) {
      this.$confirm("是否发布该产品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          productUpdateCourse({
            id: row.id,
            productStatus: 2
          }).then((res) => {
           if (res.code == 0) {
              this.msgSuccess('发布成功！')
              this.getProductList()
          }
          });
        })
        .catch(() => {});
    },
    zdySinglePriceBlur(classHour,singlePrice,key){
        this.saveRuleForm.xmtProductPackageEntityList[key].totalPrice=Number(classHour)*Number(singlePrice);
    },
    //查看详情
    daalSee(row){
        productInfoDetail(row.id).then(res=>{
            if(res.code==0){
                this.entityList=[];
                this.courseDetailSync=true;
                this.srcList.push(res.rows.photo);
                this.entityList=res.rows.xmtProductPackageEntityList;
                this.courseDetailParams={
                  photo:res.rows.photo,
                  productName:res.rows.productName,
               
                  teacherTitleId:res.rows.xmtProductPackageEntityList[0].teacherTitleName,
                  classHour:res.rows.xmtProductPackageEntityList[0].classHour,
                  singlePrice:res.rows.xmtProductPackageEntityList[0].singlePrice,
                  totalPrice:res.rows.xmtProductPackageEntityList[0].totalPrice,
                  sectionName:res.rows.xmtProductPackageEntityList[0].sectionName,
                  subjectName:res.rows.xmtProductPackageEntityList[0].subjectName,
                  peopleCountOTO:res.rows.peopleCountOTO
               }
            }
        })
    },
    daalSoldOut(row) {
      this.$confirm("是否下架该产品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          productUpdateCourse({
            id: row.id,
            productStatus: 3
          }).then((res) => {
          if (res.code == 0) {
            this.msgSuccess('下架成功！')
            this.getProductList()
          }
          });
        })
        .catch(() => {});
    },
    dealAdd() {
      this.saveDialogVisible = true;
      this.saveDialog = {
        title: "新增产品",
        type: "add",
      }
      this.saveRuleForm = {
        photo: "",
        productName: "",
        productType: '1',
        delFlag: '1',
        isSubscribe:'1',
        xmtProductPackageEntityList: [
          {
            sectionId: "",
            classHour: "",
            teacherTitleId: "",
            totalPrice: "",
            singlePrice: "",
          }
        ]
      }
    },
    dealEdit() {
      this.saveDialog = {
        title: "编辑产品",
        type: "edit",
      }
      if (this.selectProductList.length != 1) {
        this.msgWarn("请选择一个产品！");
        return;
      }
      for(let i = 0, len = this.productList.length; i < len; i++){
        if(this.productList[i].id == this.selectProductList[0]){
          if(this.productList[i].productStatus == 2){
            this.msgWarn("已发布状态的产品不能编辑！");
            return;
          }
        }
      }
      this.getProductInfo()
      this.saveDialogVisible = true
    },
    toogleExpand(row){
      this.$refs.productListTable.toggleRowExpansion(row)
    },
    getProductInfo(){
      productInfoDetail(this.selectProductList[0]).then(res => {
        if(res.code == 0){
          this.saveRuleForm = {
            photo: res.rows.photo,
            productName: res.rows.productName,
            productType: '1',
            delFlag: '1',
            isSubscribe:'1',
            xmtProductPackageEntityList: res.rows.xmtProductPackageEntityList.length > 0 ? res.rows.xmtProductPackageEntityList : [
              {
                sectionId: "",
                classHour: "",
                teacherTitleId: "",
                totalPrice: "",
                singlePrice: "",
              },
            ],
            id: res.rows.id,
          }
        }
      })
    },
    getSectionList() {
      getSelectList({
        parentCode: sectionCode,
      }).then((res) => {
        if (res.code == 0) {
          this.sectionList = res.rows;
          this.sectionList_clean = JSON.parse(JSON.stringify(res.rows));
          this.sectionList.unshift({
            macroId: "0",
            name: "全部",
          });
        }
      });
    },
    getTeachTitle() {
      getSelectList({
        parentCode: teachTitle,
      }).then((res) => {
        if (res.code == 0) {
          this.teachTitleList = res.rows;
          console.log(this.teachTitleList,'老师职称接口。。。。')
        }
      });
    },
    handleSelectionChange(val) {
      this.selectProductList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectProductList.push(val[i].id);
        }
      }
    },
    searchFn(){
      this.getProductList();
    },
    getProductList() {
      let data = {
        productStatus:
          this.listParams.productStatus == "0"
            ? null
            : this.listParams.productStatus,
        name: this.listParams.name,
        sectionId:
          this.listParams.sectionId == "0" ? "" : this.listParams.sectionId,
        subjectId:
          this.listParams.subjectId == "0" ? "" : this.listParams.subjectId,
        productType: this.listParams.productType,
        pageNumber: this.listParams.pageNumber,
        pageSize: this.listParams.pageSize,
      };
      productList(data).then((res) => {
        this.productList = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getProductList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getProductList();
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid){
          this.saveDisabled = true;
          if(this.saveDialog.type == 'add'){
            productAdd(this.saveRuleForm).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.saveDisabled = false;
                this.getProductList();
              }
            });
          }else if(this.saveDialog.type == 'edit'){
              productUpdate(this.saveRuleForm).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.saveDisabled = false;
                this.getProductList();
              }
            });
          } 
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    cancelProduct(){
      this.saveDialogVisible = false;
    },
    refreshClick() {
      this.reload();
    },
    studentItemClick(row) {
      this.$refs.productListTable.toggleRowSelection(row);
    },
    dealDelete(row) {
      let ids = [];
      if (row.id) {
        ids = [row.id];
      }else{
        if (this.selectProductList.length == 0) {
          this.msgWarn("请至少选择一个产品进行删除！");
          return;
        }
        ids = this.selectProductList
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          productRemove(ids).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getProductList();
            }
          });
        })
        .catch(() => {});
    },
    getSubjectList() {
      return getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.subjectList = res.rows;
          this.subjectList.unshift({
            macroId: "0",
            name: "全部",
          });
        }
      });
    },
  },
  created() {},
  mounted() {
    this.getProductList();
    this.getSectionList();
    this.getSubjectList();
    this.getTeachTitle();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .center {
    text-align: center;
    min-height: 30px;
  }
  .line {
    height: 0;
    border-bottom: 1px solid #f1f1f1;
    margin-left: 30px;
    margin: 10px 0 10px 30px;
  }
   .row_detail {
      padding: 10px 0;
    }
    .orderList{
      padding-bottom:20px;
      li{
        display:flex;
        margin-top:15px;
        span{
          flex:1;
        }
      }
    }
}
</style>