<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="请输入产品编号/名称"
            size="small"
            style="min-width:120px"
            @blur="()=>(listParams.pageNumber=1,getProductList())"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">视频类型：</span>
          <el-select
            v-model="listParams.videoType"
            placeholder="请选择"
            size="small"
             @change="()=>(listParams.pageNumber=1,getProductList())"
          >
            <el-option
              v-for="item in courseTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">产品状态：</span>
          <el-select
            v-model="listParams.productStatus"
            placeholder="请选择"
            size="small"
             @change="()=>(listParams.pageNumber=1,getProductList())"
          >
            <el-option
              v-for="item in productStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="()=>(listParams.pageNumber=1,getProductList())"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="dealAdd">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>添加</span>
          </el-button>
          <el-button type="default" size="small" @click="dealEdit">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="dealDelete">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="productList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="productListTable"
        @selection-change="handleSelectionChange"
        @row-click="studentItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="no"
          label="产品编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="videoType"
          :formatter="dealVideoType"
          label="视频类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="添加时间"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="dealProductStatus"
          label="产品状态"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="buyCount"
          label="购买人数"
          align="center"
        >
          <template slot-scope="scope">
            <div @click.stop="buyPersonsClick(scope.row)" style="cursor:pointer;text-decoration:underline;">{{scope.row.buyCount!=null?scope.row.buyCount:0}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="appletMembershipCount"
          label="会员人数"
          align="center"
        >
          <template slot-scope="scope">
            <div @click.stop="memberNewClick(scope.row)" style="cursor:pointer;text-decoration:underline;">{{scope.row.appletMembershipCount}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="appletNewcomerCount"
          label="小程序新人"
          align="center"
        >
          <template slot-scope="scope">
            <div @click.stop="appletNewClick(scope.row)" style="cursor:pointer;text-decoration:underline;">{{scope.row.appletNewcomerCount}}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.stop="daalSee(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="scope.row.productStatus == 1"
              @click.stop="daalPublish(scope.row)"
              >发布</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="scope.row.productStatus == 3"
              @click.stop="daalPublish(scope.row)"
              >重新发布</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="scope.row.productStatus == 2"
              @click.stop="daalSoldOut(scope.row)"
              >下架</el-button
            >
            <el-button
              type="text"
              size="small"
              @click.stop="daalSuggest(scope.row)"
            >
              <span v-show="scope.row.suggest == 0">置顶</span>
              <span v-show="scope.row.suggest == 1">取消置顶</span>
            </el-button>
            <el-button
              type="text"
              size="small"
            >
              <span v-show="scope.row.showWechatStatus == 0||scope.row.showWechatStatus == null" @click="appletPush(scope.row,1)">小程序推送</span>
              <span v-show="scope.row.showWechatStatus == 1" @click="cancelAppletPush(scope.row,0)">取消推送</span>
            </el-button>
            <el-button
              type="text"
              size="small"
              @click.stop="previewFn(scope.row)"
            >
             预览
            </el-button>
          </template>
        </el-table-column>
   
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :close-on-click-modal="false"
      :visible.sync="saveDialogVisible"
      width="900px"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="120px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="产品封面" prop="photo">
                <el-upload
                  class="avatar-uploader"
                  :action="fileUpload"
                  :show-file-list="false"
                  accept="image/jpeg, image/png/, image/gif"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="saveRuleForm.photo"
                    :src="saveRuleForm.photo"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <span>建议图片尺寸:650*480</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="产品名称" prop="productName">
                <el-input v-model="saveRuleForm.productName"></el-input>
              </el-form-item>
              <el-form-item
                  label="学科"
                  prop="gkkSubject"
                >
                  <el-select v-model="saveRuleForm.gkkSubject" placeholder="请选择">
                    <el-option
                      v-for="option in subjectListSelect"
                      :key="option.macroId"
                      :label="option.name"
                      :value="option.macroId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              <el-form-item label="学段" prop="gkkPeriod">
                <el-select
                  v-model="saveRuleForm.gkkPeriod"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="option in phaseList"
                    :key="option.macroId"
                    :label="option.name"
                    :value="option.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="原价" prop="originalPrice">
                <el-input v-model.number="saveRuleForm.originalPrice"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="优惠价" prop="preferentialPrice">
                <el-input v-model.number="saveRuleForm.preferentialPrice"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="浏览人数" prop="visitorsNumber">
                <el-input v-model.number="saveRuleForm.visitorsNumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="视频类型" prop="videoType">
            <el-radio-group v-model="saveRuleForm.videoType">
              <el-radio label="1">直播课</el-radio>
              <el-radio label="2">一对一</el-radio>
              <el-radio label="3">班课</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="课程简介" prop="introduce">
            <editor
              :content_height="content_height"
              :set_editor_content="set_editor_introduce"
              @edit_content_change="edit_introduce_change"
            ></editor>
          </el-form-item>
           <el-form-item label="添加课程" prop="" style="margin-top:20px;" v-show="saveRuleForm.videoType!=1"> 
               <el-button type="primary" @click="addCourse()" >添加课程</el-button>
          </el-form-item>
          <el-table
            v-show="saveRuleForm.videoType!=1"
            ref="multipleTable"
            :data="courseData"
            tooltip-effect="dark"
            style="width: 100%">
            <el-table-column
              width="55">
            </el-table-column>
            <el-table-column
              type="index"
              label="序号"
              width="120">
            </el-table-column>
            <el-table-column
              prop="vedioName"
              label="课程名称">
            </el-table-column>
              <el-table-column
              prop="vedioAddress"
              label="课程视频">
            </el-table-column>
             <el-table-column
              prop="classHour"
              label="课时数">
            </el-table-column>
             <el-table-column
              prop="teacherName"
              label="讲师姓名">
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"> 
               <template slot-scope="scope">
                <el-button type="text" size="small" @click="removeCourse(scope.$index, courseData)"
                  >删除</el-button
                >
                 <el-button type="text" size="small" @click="eitCourse(scope.row,scope.$index)"
                  >修改</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          :disabled="saveDisabled"
          @click="confirmSave('saveRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="courseDialog.title"
      :visible.sync="courseDialogVisible"
      width="1000px"
      :close-on-click-modal="false"
      center
      
    >
      <div class="dialog-content">
          <el-form
          :model="courseRuleForm"
          status-icon
          :rules="courseRules"
          ref="courseRuleForm"
          label-width="120px"
          size="small"
        > 
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="课程名称" prop="vedioName">
                <el-input v-model="courseRuleForm.vedioName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="上传类型" >
                <el-radio-group v-model="upType">
                  <el-radio label="1">视频类型</el-radio>
                  <el-radio label="2">链接类型</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20" v-show="upType==1">
            <el-col :span="20">
              <el-form-item label="课程视频" prop="vedioAddress" ref="uploadvideo">
                <upload-video ref="upload" @video_change="video_change" :default_src="courseRuleForm.vedioAddress" ></upload-video>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="upType==2">
            <el-col :span="20">
              <el-form-item label="链接" prop="vedioAddress" >
                <el-input v-model="courseRuleForm.vedioAddress"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="课时数" prop="classHour">
                <el-input v-model="courseRuleForm.classHour"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="讲师姓名" prop="teacherName">
                <el-input v-model="courseRuleForm.teacherName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="讲师介绍" prop="teacherIntroduce">
                <el-input v-model="courseRuleForm.teacherIntroduce"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelCourse()" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmCourseSave('courseRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="产品详情"
      :visible.sync="courseDetailSync"
      width="800px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
          <el-col :span="8">
            <span>产品封面：</span>
            <el-image
              style="width: 100px; height: 100px"
              :src="courseDetailParams.photo"
              v-if="courseDetailParams.photo"
                :preview-src-list="srcList"
            >
            </el-image>
          </el-col>
          <el-col :span="8">
            <span>产品名称：</span>
            <span>{{ courseDetailParams.productName }}</span>
          </el-col>
          <el-col :span="8">
            <span>视频类型：</span>
            <span>{{courseDetailParams.videoType}}</span>
          </el-col>
          
        </el-row>
        <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
          <el-col :span="8">
            <span>原价：</span>
            <span>{{courseDetailParams.originalPrice}}元</span>
          </el-col>
          <el-col :span="8">
            <span>优惠价：</span>
            <span>{{courseDetailParams.preferentialPrice}}元</span>
          </el-col>
          <el-col :span="8">
            <span>观看人数：</span>
            <span>{{courseDetailParams.visitorsNumber?courseDetailParams.visitorsNumber:0}}人</span>
          </el-col>
        </el-row>
         <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
          <el-col :span="8">
            <span>学段：</span>
            <span>{{ courseDetailParams.gkkPeriod }}</span>
          </el-col>
          <el-col :span="8">
            <span>学科：</span>
            <span>{{courseDetailParams.gkkSubject}}</span>
          </el-col>
          
        </el-row>
        <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
          <el-col :span="20">
            <span>课程简介：</span>
            <span style="margin-top:20px;display:block;" v-html="courseDetailParams.introduce"></span>
          </el-col>
        </el-row>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick" style="margin-top:30px;">
          <el-tab-pane label="课程大纲" name="first">
              <el-table
                size="small"
                :data="outlineList"
                style="width: 100%; margin-bottom: 20px"
                row-key="id"
                border
                height="100%"
                tooltip-effect="dark"
                ref="productListTable"
              >
                <el-table-column
                  prop="vedioName"
                  label="课程列表"
                  align="center"
                ></el-table-column>
                 <el-table-column
                  prop="teacherName"
                  label="讲师姓名"
                  align="center"
                ></el-table-column>
                 <el-table-column
                  prop="vedioAddress"
                  label="视频地址"
                  align="center"
                ></el-table-column>
              </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
    <el-dialog
      title="设置免费时长"
      :visible.sync="appletPushVisible"
      :close-on-click-modal="false"
      width="500px"
      center
    >
      <div class="" style="padding:40px 0 60px 0;text-align:center;">
        <div class="item_select">
          未授权用户可免费观看
          <el-input
            v-model="appletParams.freeVisitTime"
            placeholder=""
            size="small"
           onkeyup="value=value.replace(/^0+(\d)|[^\d]+/g,'')"
            style="width:90px;margin:0 8px;"
          ></el-input>秒
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="appletPushVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary"  size="small"
          @click="appletPushSure()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <openClassPreview ref="openClassPreview" v-if="Visible" :visible="Visible" :visibleId="visibleId" :visibleType="visibleType" @close="Visible=false"></openClassPreview>
    <appletNew ref="appletNew" :appletNewStatus="appletNewStatus" :appletNewId="appletNewId" @CB_appletNewStatus="CB_appletNewStatus"></appletNew>
    <buyPersons ref="buyPersons" :buyPersonsId="buyPersonsId" :buyPersonsStatus="buyPersonsStatus" @CB_buyPersonStatus="CB_buyPersonStatus"></buyPersons>
    <memberNew ref="memberNew"  :memberNewId="memberNewId" :memberNewStatus="memberNewStatus" @CB_memberNewStatus="CB_memberNewStatus"></memberNew>
  </div>
</template>

<script>
import {
  productList,
  productRemove,
  productOnToAllAdd as productAdd,
  productInfo as productInfoDetail,
  productUpdateCourse,setShowWechatStatus,
  getWechatProductUserTimeList,
  getWechatProductUserList
} from "@/api/operates/productManage";
import {
  getSelectList,
  teachTitle,
  fileUpload,
  phaseCode,
  subjectCode
} from "@/api/public";
import openClassPreview from "./dialog/openClassPreview.vue";
import appletNew from "./dialog/appletNew.vue";//小程序新人
import buyPersons from "./dialog/buyPersons.vue";//购买人数
import memberNew from "./dialog/memberNew.vue";//会员人数
let id = 0;
export default {
  inject: ["reload"],
  components:{
    openClassPreview,
    appletNew,
    buyPersons,
    memberNew,
  },
  data() {
    return {
      appletNewStatus:false,
      buyPersonsStatus:false,
      memberNewStatus:false,
      memberNewId:"",
      appletNewId:"",
      appletParams:{
        id:"",
        showWechatStatus:"",
        freeVisitTime:""
      },
      appletPushVisible:false,
      Visible:false,
      docImg:require("@/assets/image/word.png"),
      jpgImg:require("@/assets/image/jpg.png"),
      pptImg:require("@/assets/image/ppt.png"),
      pngImg:require("@/assets/image/png.png"),
      progressNum:"",
      srcList:[],
      meansList:[],
      outlineList:[],
      activeName: 'first',
      courseDetailParams:{},
      courseDetailSync:false,
      courseDialogVisible:false,
      getIndex:'',
      saveDisabled:false,
      courseDialog:{
        title:"添加课程",
        type:"add"
      },
      courseRuleForm:{
        vedioName:"",
        vedioAddress:"",
        classHour:"",
        teacherName:"",
        teacherIntroduce:"",
        
      },
      courseRules:{
        vedioName: [
          { required: true, message: "课程名称不能为空", trigger: "blur" }
        ],
        classHour: [
          { required: true, message: "课时数不能为空", trigger: "blur" }
        ],
         teacherName: [
          { required: true, message: "讲师姓名不能为空", trigger: "blur" }
        ],
        vedioAddress:[
          { required: true, message: "课程视频不能为空", trigger: "change" }
        ]
      },
      courseData:[],
      content_height: 150,
      fileUpload,
      productList: [],
      subjectList: [
        {
          macroId: "0",
          name: "全部",
        },
      ],
      upType:'1',
      subjectListSelect:[],
      listParams: {
        name: "",
        videoType: '0',
        productStatus: "0",
        productType: 4,
        pageNumber: 1,
        pageSize: 10,
      },
      productStatusList: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "已保存未发布",
        },
        {
          value: "2",
          label: "已发布",
        },
        {
          value: "3",
          label: "已下架",
        },
      ],
      courseTypeList: [
        {
          value: '0',
          label: '全部'
        },
        {
          value: "1",
          label: "直播课",
        },
        {
          value: "2",
          label: "一对一",
        },
        {
          value: "3",
          label: "班课",
        },
      ],
      total: 0,
      visibleId:"",
      visibleType:"",
      saveDialogVisible: false,
      saveRuleForm: {
        photo: "",
        productName: "",
        gkkSubject: '',
        gkkPeriod: "",
        videoType: '1',  //视频类型：1、直播课；2、一对一；3、大班课；4、小班课；5、答疑课
        introduce: "", //课程简介
        productType: "4",
        delFlag: "1",
        originalPrice:0,
        preferentialPrice:0,
        visitorsNumber:0
      },
      teachTitleList: [],
      saveRules: {
        preferentialPrice: [
          { required: true, message: "优惠价不能为空", trigger: "blur" },
        ],
        visitorsNumber: [
          { required: true, message: "浏览人数不能为空", trigger: "blur" },
        ],
        photo: [
          { required: true, message: "产品封面不能为空", trigger: "blur" },
        ],
        productName: [
          { required: true, message: "产品名称不能为空", trigger: "blur" },
        ],
        gkkSubject: [
          { required: true, message: "学科不能为空", trigger: "blur" },
        ],
        gkkPeriod: [
          { required: true, message: "学段不能为空", trigger: "blur" },
        ],
        vedioName: [
          { required: true, message: "课程名称不能为空", trigger: "blur" },
        ],
        vedioAddress: [
          { required: true, message: "视频不能为空", trigger: "blur" },
        ],
        gkkClassHour: [
          { required: true, message: "课时总价不能为空", trigger: "blur" },
          { type: "number", message: "总价必须为数字", trigger: "blur" },
        ],
        gkkTeacherName: [
          { required: true, message: "课时单价不能为空", trigger: "blur" },
        ],
      },
      selectProductList: [],
      saveDialog: {
        title: "新增产品",
        type: "add",
      },
      set_editor_introduce: "",
      set_editor_outlines: "",
      phaseList: [],
      buyPersonsId:"",

    };
  },
   watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    },
    // courseDialogVisible: function (val,oldVla) {
    //   if (this.$refs['courseRuleForm'] !== undefined) {
    //     this.$refs["courseRuleForm"].resetFields();
    //   }
    // }
  },
  methods: {
    handleClickBigFileUpload() {
      this.$refs.bigFileUpload.click();
      this.$refs.bigFileUpload.value = "";
    },
    CB_buyPersonStatus(){
      this.buyPersonsStatus=false;
    },
    CB_memberNewStatus(){this.memberNewStatus=false},
    CB_appletNewStatus(){this.appletNewStatus=false},
    buyPersonsClick(row){
      this.buyPersonsStatus=true;
      this.buyPersonsId=row.id;
    },
    memberNewClick(row){
      this.memberNewStatus=true;
      this.memberNewId=row.id;
    },
    appletNewClick(row){
      this.appletNewStatus=true;
      this.appletNewId=row.id;
    },
    previewFn(row){
      this.Visible=true;
      this.visibleId=row.id;
      this.visibleType=row.videoType;
    },
    appletPushSure(){
       setShowWechatStatus(this.appletParams)
          .then(res => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.appletPushVisible=false;
              this.getProductList();
            }
          })
          .catch(() => {});
    },
    appletPush(row,num){
      this.appletParams.freeVisitTime=""
      this.appletParams.showWechatStatus=num;
      this.appletParams.id=row.id;
      this.appletPushVisible=true;
    },
    cancelAppletPush(row,num){
      this.appletParams.showWechatStatus=num;
      this.appletParams.id=row.id;
      this.$confirm("确定取消推送吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setShowWechatStatus(this.appletParams)
          .then(res => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getProductList();
            }
          })
          .catch(() => {});
        })
        .catch(() => {});
    },
    video_change(url) {
      this.courseRuleForm.vedioAddress = url;
      this.$refs.uploadvideo.clearValidate();
    },
    dealProductStatus(row) {
      let status = "";
      for (let i = 0, len = this.productStatusList.length; i < len; i++) {
        if (row.productStatus == this.productStatusList[i].value) {
          status = this.productStatusList[i].label;
        }
      }
      return status;
    },
    handleAvatarSuccess(res, file) {
      this.saveRuleForm.photo = res[0].url;
    },
    beforeAvatarUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
          this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
          return false;
      } else {
      }
      // let reg = /\.(png|jpg|gif|jpeg)$/;
      // if (!reg.test(file.name)) {
      //   this.msgError(
      //     "支持上传的图片格式为png，jpg，gif，jpeg；请检查格式是否正确！"
      //   );
      // }
      // return reg.test(file.name);
    },
    edit_introduce_change(html) {
      this.saveRuleForm.introduce = html;
      this.set_editor_introduce = html;
    },
    edit_outlines_change(html) {
      this.saveRuleForm.outlines = html;
    },
    dealVideoType(row){
      let type 
      switch (row.videoType) {
        case 1:
          type = '直播课'
          break;
        case 2:
          type = '一对一'
          break;
        case 3:
          type = '班课'
          break;
        default:
          type = ''
          break;
      }
      return type
    },
    daalSuggest(row) {
      this.$confirm(`${row.suggest == "0" ? "确定要置顶吗?" : "确定要取消置顶吗?"}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        productUpdateCourse({
        id: row.id,
       suggest: row.suggest == "0" ? 1 : 0,
      })
          .then(res => {
            if (res.code == 0) {
            this.msgSuccess(
            `${row.suggest == "0" ? "置顶成功！" : "取消置顶成功！"}`
          );
          this.getProductList();
            }
          })
          .catch(() => {});
      });
    },
    //查看详情
    daalSee(row){
      this.activeName='first';
      productInfoDetail(row.id).then(res=>{
        if(res.code==0){
          this.courseDetailSync=true;
          this.srcList.push(res.rows.photo);
          this.courseDetailParams.productName=res.rows.productName;
          this.courseDetailParams.introduce=res.rows.introduce;
          this.courseDetailParams.photo=res.rows.photo;
          this.courseDetailParams.originalPrice=res.rows.originalPrice;
          this.courseDetailParams.preferentialPrice=res.rows.preferentialPrice;
          this.courseDetailParams.visitorsNumber=res.rows.visitorsNumber;
          this.outlineList=res.rows.xmtProductCourseEntity;
          if(res.rows.videoType==1){
            this.courseDetailParams.videoType='直播课'
          }else if(res.rows.videoType==2){
            this.courseDetailParams.videoType='一对一'
          }else if(res.rows.videoType==3){
            this.courseDetailParams.videoType='班课'
          }

          if(res.rows.gkkPeriod==107){
            this.courseDetailParams.gkkPeriod='高中'
          }else if(res.rows.gkkPeriod==106){
            this.courseDetailParams.gkkPeriod='初中'
          }else if(res.rows.gkkPeriod==105){
            this.courseDetailParams.gkkPeriod='小学'
          }else if(res.rows.gkkPeriod==108){
            this.courseDetailParams.gkkPeriod='艺术培训'
          }else if(res.rows.gkkPeriod==109){
            this.courseDetailParams.gkkPeriod='小语种'
          }
          if(res.rows.gkkSubject==185){
            this.courseDetailParams.gkkSubject='全学科'
          }else if(res.rows.gkkSubject==23){
            this.courseDetailParams.gkkSubject='语文'
          }else if(res.rows.gkkSubject==24){
            this.courseDetailParams.gkkSubject='数学'
          }else if(res.rows.gkkSubject==25){
            this.courseDetailParams.gkkSubject='英语'
          }else if(res.rows.gkkSubject==101){
            this.courseDetailParams.gkkSubject='物理'
          }else if(res.rows.gkkSubject==102){
            this.courseDetailParams.gkkSubject='化学'
          }else if(res.rows.gkkSubject==99){
            this.courseDetailParams.gkkSubject='政治'
          }else if(res.rows.gkkSubject==100){
            this.courseDetailParams.gkkSubject='生物'
          }else if(res.rows.gkkSubject==87){
            this.courseDetailParams.gkkSubject='地理'
          }else if(res.rows.gkkSubject==88){
            this.courseDetailParams.gkkSubject='历史'
          }else if(res.rows.gkkSubject==103){
            this.courseDetailParams.gkkSubject='科学'
          }else if(res.rows.gkkSubject==112){
            this.courseDetailParams.gkkSubject='国学'
          }else if(res.rows.gkkSubject==113){
            this.courseDetailParams.gkkSubject='书法'
          }else if(res.rows.gkkSubject==114){
            this.courseDetailParams.gkkSubject='吉他'
          }else if(res.rows.gkkSubject==115){
            this.courseDetailParams.gkkSubject='绘画'
          }else if(res.rows.gkkSubject==116){
            this.courseDetailParams.gkkSubject='德语'
          }else if(res.rows.gkkSubject==117){
            this.courseDetailParams.gkkSubject='法语'
          }else if(res.rows.gkkSubject==118){
            this.courseDetailParams.gkkSubject='俄语'
          }else if(res.rows.gkkSubject==119){
            this.courseDetailParams.gkkSubject='西班牙语'
          }else if(res.rows.gkkSubject==183){
            this.courseDetailParams.gkkSubject='其他小语种'
          }else if(res.rows.gkkSubject==184){
            this.courseDetailParams.gkkSubject='其他艺术课'
          }
        } 
      })
    },
    handleClick(){

    },
    daalPublish(row) {
      this.$confirm("确定要发布吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        productUpdateCourse({
        id: row.id,
        productStatus: 2,
      })
          .then(res => {
            if (res.code == 0) {
             this.msgSuccess("发布成功！");
             this.getProductList();
            }
          })
          .catch(() => {});
      });
    },
    daalSoldOut(row) {
       this.$confirm("确定要下架吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        productUpdateCourse(
          {
            id: row.id,
            productStatus: 3,
          }
        )
          .then(res => {
            if (res.code == 0) {
              this.msgSuccess("下架成功！");
              this.getProductList();
            }
          })
          .catch(() => {});
      });
     
    },
    
    dealAdd() {
      this.saveDialogVisible = true;
      this.courseData=[];
      this.saveDialog = {
        title: "新增产品",
        type: "add",
      };
      this.set_editor_introduce = "";
      this.set_editor_outlines = "";
      this.saveRuleForm = {
        delFlag: "1",
        gkkPeriod: "",
        gkkSubject:"",
        introduce: "", //课程简介
        photo: "",
        productName: "",
        videoType: '2',  //视频类型：1、直播课；2、一对一；3、大班课；4、小班课；5、答疑课
        productType: "4",
        originalPrice:0,
        preferentialPrice:0,
        visitorsNumber:0
      };
    },
    dealEdit() {
      this.saveDialog = {
        title: "编辑产品",
        type: "edit",
      };
      if (this.selectProductList.length != 1) {
        this.msgWarn("请选择一个产品！");
        return;
      }
       for(let i = 0, len = this.productList.length; i < len; i++){
        if(this.productList[i].id == this.selectProductList[0]){
          if(this.productList[i].productStatus == 2){
            this.msgWarn("已发布状态的产品不能编辑！");
            return;
          }
        }
      }
      this.getProductInfo();
      this.saveDialogVisible = true;
    },
    searchFn(){
        this.getProductList()
    },
    toogleExpand(row) {
      this.$refs.productListTable.toggleRowExpansion(row);
    },
    getProductInfo() {
      productInfoDetail(this.selectProductList[0]).then((res) => {
        if (res.code == 0) {
          this.set_editor_introduce = res.rows.introduce;
          this.set_editor_outlines = res.rows.outlines;
          this.saveRuleForm = {
            photo: res.rows.photo,
            productName: res.rows.productName,
            gkkSubject: res.rows.gkkSubject, 
            gkkPeriod: res.rows.gkkPeriod,
            videoType: String(res.rows.videoType),
            introduce: res.rows.introduce, 
            outlines: res.rows.outlines,
            productType: "4",
            delFlag: "1",
            id: res.rows.id,
            vedioName: res.rows.vedioName,
            vedioAddress: res.rows.vedioAddress,
            gkkClassHour:res.rows.gkkClassHour,
            gkkTeacherName: res.rows.gkkTeacherName,
            gkkIntroduce: res.rows.gkkIntroduce,
            originalPrice:res.rows.originalPrice,
            preferentialPrice:res.rows.preferentialPrice,
            visitorsNumber:res.rows.visitorsNumber
          };
          this.productCourseData=res.rows.xmtProductCourseMaterialsEntity;
          this.courseData=res.rows.xmtProductCourseEntity;
        }
      });
    },
    getPhaseList() {
      getSelectList({
        parentCode: phaseCode,
      }).then((res) => {
        if (res.code == 0) {
          this.phaseList = res.rows;
        }
      });
    },
    getTeachTitle() {
      getSelectList({
        parentCode: teachTitle,
      }).then((res) => {
        if (res.code == 0) {
          this.teachTitleList = res.rows;
        }
      });
    },
    handleSelectionChange(val) {
      this.selectProductList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectProductList.push(val[i].id);
        }
      }
    },
    getProductList() {
      let data = {
        productStatus:
          this.listParams.productStatus == "0"
            ? null
            : this.listParams.productStatus,
        name: this.listParams.name,
        productType: this.listParams.productType,
        videoType: this.listParams.videoType == '0' ? '' : this.listParams.videoType,
        pageNumber: this.listParams.pageNumber,
        pageSize: this.listParams.pageSize,
      };
      productList(data).then((res) => {
        this.productList = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getProductList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getProductList();
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveDisabled = true;
          if (this.saveDialog.type == "add") {
            this.saveRuleForm.xmtProductCourseEntity  =this.courseData;
            productAdd(this.saveRuleForm).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.saveDisabled = false;
                this.getProductList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            this.saveRuleForm.xmtProductCourseEntity  =this.courseData;
            productUpdateCourse(this.saveRuleForm).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                 this.saveDisabled = false;
                this.getProductList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    refreshClick() {
      this.reload();
    },
    studentItemClick(row) {
      this.$refs.productListTable.toggleRowSelection(row);
    },
    successCourse(){

    },
    errorCourse(){

    },
    removeCourse(index,rows){
      rows.splice(index, 1);
    },
    eitCourse(val,index){
      console.log(val,'val...')
      this.courseDialogVisible=true;
      this.courseDialog.title="编辑课程";
      this.courseDialog.type="edit";
      this.courseRuleForm=val;
      this.getIndex=index;
    },
   
    confirmCourseSave(formName){
        this.$refs[formName].validate((valid) => {
          //  this.courseData=[];
          if (valid){
            if(this.courseDialog.type=='add'){
              this.courseData.push(this.courseRuleForm);
            }else if(this.courseDialog.type=='edit'){
              this.courseData[this.getIndex]=this.courseRuleForm;
            }
            this.courseDialogVisible=false;
          }else {                                       
            this.msgWarn("必填字段不能为空！");
            return false;
          }
        })
    },
    cancelCourse(){
      this.courseDialogVisible = false;
      this.$refs['courseRuleForm'].resetFields();
    },
    //添加课程
    addCourse(){
      // this.$refs.upload.clearFiles();
      this.courseDialog.title='添加课程';
      this.courseDialog.type='add';
      this.courseDialogVisible=true;
      this.progressNum="";
      this.courseRuleForm={
        vedioName:"",
        vedioAddress:"",
        classHour:"",
        teacherName:"",
        teacherIntroduce:""
      };
    },
    dealDelete(row) {
      let ids = [];
      if (row.id) {
        ids = [row.id];
      } else {
        if (this.selectProductList.length == 0) {
          this.msgWarn("请至少选择一个产品进行删除！");
          return;
        }
        ids = this.selectProductList;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          productRemove(ids).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getProductList();
            }
          });
        })
        .catch(() => {});
    },
    getSubjectList() {
      return getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.subjectList = res.rows;
          this.subjectList.unshift({
            macroId: "0",
            name: "全部",
          });
        }
      });
    },
     getSubjectListSelect() {
      return getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.subjectListSelect = res.rows;
        }
      });
    },
  },
  created() {},
  mounted() {
    this.getProductList();
    this.getTeachTitle();
    this.getPhaseList();
    this.getSubjectList();
    this.getSubjectListSelect();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .center {
    text-align: center;
    min-height: 30px;
  }
  .line {
    height: 0;
    border-bottom: 1px solid #f1f1f1;
    margin-left: 30px;
    margin: 10px 0 10px 30px;
  }
  .save-dialog-content {
    padding-right: 20px;
  }
  .row_detail {
      padding: 10px 0;
    }
}

</style>