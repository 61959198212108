<template>

    <el-dialog
        title="选择讲师"
        center
        width="800px"
        close-on-press-escape
        @close="closeDialog"
        :visible.sync="dialogClassStatus"
        :close-on-click-modal="false"
    >
        <div class="dialog-content">
            <div class="table-area">
                <el-table
                    size="small"
                    :data="teacherlist"
                    style="width: 100%;margin-bottom: 20px;"
                    border
                    height="400px"
                    tooltip-effect="dark"
                    :row-key="getRowKeys"
                    ref="resTeacherListTable"
                    @selection-change="handleTeacherSelectionChange"
                    @row-click="rowTeacherItemClick"
                >
                    <el-table-column type="selection" width="55" align="center" />
                    <el-table-column
                      prop="subjectName"
                      label="学科"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="teacherRealname"
                      label="讲师"
                      align="center"
                    ></el-table-column>
                    <el-table-column prop="createTime" label="期望薪资" align="center">
                    <template slot-scope="scope">
                        <span
                        >{{ scope.row.hopeMinFee }}~{{ scope.row.hopeMaxFee }}</span
                        >
                    </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="sureOpenTeacher()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
</template>
<script>
import {
  configteacherPlan,
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
export default {
    props:["classStatus","params"],
    watch:{
        classStatus(newValue,oldValue){
            this.dialogClassStatus=newValue;
            if(newValue==true){
                this.listParams.subjectId=this.params.subjectId;
                this.listParams.productId=this.params.productId;
                this.configteacherPlanFn();
            }
        }
    },
    data(){
        return{
            teacherlist:[],
            total:0,
            teacherSelectionHandle:[],
            checkedGh:[],
            dialogClassStatus:false,
            listParams:{
                status: 1,
                subjectId: "",
                productId:"",
                pageNumber: 1,
                pageSize: 10
            }
        }
    },
    methods:{
        sureOpenTeacher(){
            this.closeDialog();
            this.$emit('callBackTea',this.teacherSelectionHandle[0])
        },
        configteacherPlanFn(){
            configteacherPlan(this.listParams).then(res => {
                this.teacherlist = res.rows;
                this.total=res.total;
            });
        },
        getRowKeys(row) {
            return row.id;
        },
        closeDialog(){
            this.$emit('CB_classStatus')
        },
        handleTeacherSelectionChange(val) {
            this.teacherSelectionHandle = val;
            this.checkedGh = val.id;
              if (val.length > 1) {
                this.$refs.resTeacherListTable.clearSelection();
                this.$refs.resTeacherListTable.toggleRowSelection(val.pop());
            }
        },
        rowTeacherItemClick(row) {
            this.$refs.resTeacherListTable.toggleRowSelection(row);
        },
    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .studentInfo{
    h2{
      font-size:16px;
      margin-top:20px;
    }
    ul{
      display: flex;
      flex-wrap:wrap;
      margin-top:10px;
      li{
        width:33.3%;
        padding:5px 0;
      }
    }
  }
  .infoRecord{
    display: flex;
    width:100%;
    span{
      width:25%;
      display: inline-block;
      padding:5px 0;
    }
  }
}
.teachContent {
  display: flex;
  flex-direction: column;
}
.infoDetails {
  height: 150px;
  padding-top: 20px;
  display: flex;
}
.infoDetails .left {
  display: flex;
  width: 35%;
  min-width: 500px;
}
.infoDetails .content {
  width: 350px;
}
.infoDetails .content ul {
  margin-top: 30px;
}
.infoDetails .content ul li {
  margin-top: 5px;
}
.infoDetails .content ul span:nth-child(1) {
  font-size: 15px;
  color: #414a60;
}
.infoDetails .content ul span:nth-child(2) {
  font-size: 15px;
  color: #61687c;
}
.infoDetails .right {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
}
</style>