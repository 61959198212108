<template>
    <div class="main-content sys-role-manage">
        <div class="btn-area select-condition" >
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="课程标题/授课老师/学生姓名"
            size="small"
            style="width: 200px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课日期：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">学科：</span>
          <el-select
            v-model="listParams.subjectId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in subjectIdList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">上课状态：</span>
          <el-select
              v-model="listParams.attendClassStatus"
              placeholder="请选择"
              size="small"
          >
              <el-option
              v-for="item in attendClassStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              ></el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="()=>(listParams.pageNumber=1,findMoreProductItemPageFn())"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="table-area" >
            <el-table
              size="small"
              :data="dataList"
              style="width: 100%; margin-bottom: 20px"
              row-key="userId"
              height="100%"
              border
              tooltip-effect="dark"
            >
              <el-table-column
                prop="title"
                label="班级名称/产品名称"
                align="center"
              >
                <template slot-scope="scope">
                  {{scope.row.roomName?scope.row.roomName:scope.row.productName}}
                </template>
              </el-table-column>
              <el-table-column
                prop="title"
                label="课程标题"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="title"
                label="产品类型"
                align="center"
              >
              <template slot-scope="scope">
                {{scope.row.productType==2||scope.row.productType==5?'一对多':'公开课'}}
              </template>
              </el-table-column>
              <el-table-column
                prop="teacherRealName"
                label="授课老师"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="peopleUsedNum"
                label="学生数量"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="beginTime"
                label="上课时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="usedClassHour"
                label="课时数"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop=""
                label="上课状态"
                align="center"
                :formatter="formatterAttendClassStatus"
              >
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    :disabled="scope.row.attendClassStatus==1||scope.row.attendClassStatus==2?true:false"
                    @click="editClass(scope.row)"
                    >修改</el-button
                  > 
                   <el-button
                    type="text"
                    size="small"
                    :disabled="scope.row.attendClassStatus == 2"
                    @click="removeClass(scope.row)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="recoveryCourse(scope.row)"
                     :disabled="(scope.row.attendClassStatus==2||scope.row.attendClassStatus==0)||recoveryType(scope.row)?true:false"
                    >恢复课程</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="sureClass(1, scope.row)"
                    v-if="scope.row.usedStatus == 0"
                    >确认课时</el-button
                  >
                  <el-button
                    type="text"
                    disabled
                    disabledsize="small"
                    size="small"
                    @click="sureClass(0, scope.row)"
                    v-else
                    >已确认</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    :disabled="scope.row.lskbTz==1?true:false"
                    @click="noticeClick(scope.row,'teacher')"
                    >通知</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
    </div>
    <div class="page-area" >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <arrangeClass ref="arrangeClass" :tableRadioInfo="tableRadioInfo" @callBackInfo="callBackInfo" :timeStatus="timeStatus" @CB_timeStatus="CB_timeStatus" :dataParams="infoParams"></arrangeClass>
    <selectTeacherClass ref="selectTeacherClass" @callBackTea="callBackTea" :classStatus="classStatus" @CB_classStatus="CB_classStatus" :params="classParams"></selectTeacherClass>
    </div>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  reg_Num,
} from "@/api/public";
import dayjs from "dayjs";
import {
  deleteLiveBroadcastClass,//公开课删除
} from "@/api/operates/productManage";
import { sendCourseRemind} from "@/api/system/systemManage";
import arrangeClass from "./dialog/arrangeClass.vue";
import selectTeacherClass from "./dialog/selectTeacherClass.vue";
import {resumeClass,findMoreProductItemPage,confirmClassHour} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
export default {
    components:{
      arrangeClass,
      selectTeacherClass
    },
    data(){
        return{
            infoParams:{},
            tableRadioInfo:{},
            classParams:{},
            classStatus:false,
            timeStatus:false,
            visibelList:[],
            Visible:false,
            listParams:{
                dataAuthority:"dept",
                name:"",
                sectionId:"",//年级
                subjectId:"",//科目
                attendClassStatus:"",//上课状态
                teacherStatus:"",//教师状态
                studentStatus:"",//学生状态
                pageNumber: 1,
                pageSize: 10,
                orderType:2,
                searchBeginDate:"",
                searchEndDate:"",
            },
            dataList:[],
            total:0,
            timePickerValue:"",
            subjectIdList:[],
            attendClassStatusList:[
                {value:'',label:"全部"},
                {value:2,label:"上课中"},
                {value:0,label:"未上课"},
                {value:1,label:"已上课"},
            ],
            pickerOptions: {
                shortcuts: [
                {
                    text: "今天",
                    onClick(picker) {
                    const end = new Date(
                        dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
                    );
                    const start = new Date(
                        dayjs().format("YYYY-MM-DD") + " 00:00:00"
                    );
                    picker.$emit("pick", [start, end]);
                    },
                },
                {
                    text: "昨天",
                    onClick(picker) {
                    const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
                    const start = new Date(
                        dayjs().subtract(1, "day").format("YYYY-MM-DD") + " 00:00:00"
                    );
                    picker.$emit("pick", [start, end]);
                    },
                },
                {
                    text: "最近一周",
                    onClick(picker) {
                    const end = new Date(
                        dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
                    );
                    const start = new Date(
                        dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00"
                    );
                    picker.$emit("pick", [start, end]);
                    },
                },
                {
                    text: "最近一个月",
                    onClick(picker) {
                    const end = new Date(
                        dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
                    );
                    const start = new Date(
                        dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00:00"
                    );
                    picker.$emit("pick", [start, end]);
                    },
                },
                {
                    text: "最近三个月",
                    onClick(picker) {
                    const end = new Date(
                        dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
                    );
                    const start = new Date(
                        dayjs().subtract(90, "day").format("YYYY-MM-DD") + " 00:00:00"
                    );
                    picker.$emit("pick", [start, end]);
                    },
                }
                ]
            }
        }
    },
    created(){
        this.getSubjectList();
    },
    mounted(){
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (month < 10) {
          month = "0" + month;
      }
      if (day < 10) {
          day = "0" + day;
      }
      this.timePickerValue=[year + "-" + month + "-" + day+' 00:00:00',year + "-" + month + "-" + day+' 23:59:59'];
      this.listParams.searchBeginDate = this.timePickerValue[0];
      this.listParams.searchEndDate = this.timePickerValue[1];
      this.findMoreProductItemPageFn();
   },
    methods:{
        findMoreProductItemPageFn(){
          findMoreProductItemPage(this.listParams).then(res => {
                this.dataList=res.rows;
                this.total=res.total;
            });
        },
        callBackInfo(data){
          this.classParams=data;
          this.classStatus=true;
        },
        callBackTea(data){
          this.tableRadioInfo=data;
        },
        CB_classStatus(){
          this.classStatus=false;
        },
        CB_timeStatus(){
          this.timeStatus=false;
          this.findMoreProductItemPageFn();
        },
        editClass(row){
          this.infoParams.type="edit";
          this.infoParams.classId=row.id;
          this.infoParams.productType=row.productType;
          this.infoParams.classroomId=row.classroomId;
          this.infoParams.oneToMoreClassNum = row.peopleNum
          this.timeStatus=true;
        },
        noticeClick(row,type){
          const noticeList=[];
              noticeList.push({
              "stopTime":row.stopTime,
              "usedClassHour":row.usedClassHour,
              "classAddress":row.classAddress,
              "beginTime": row.beginTime,
              "classMode": row.classMode,
              "id": row.id,
              "sectionName": row.sectionName,
              "studentName": row.studentName,
              "subjectName": row.subjectName,
              "teacherName": row.teacherName,
              "teacherRealName": row.teacherRealName,
              "parChatId":row.parChatId,
              "roomName":row.roomName,
              "toAccount": type=='teacher'?row.teaChatId:row.stuChatId,
              "classroomId":row.classroomId
            })
            if(row.lskbTz==0){
              sendCourseRemind(noticeList).then((res) => {
                if (res.code == 0) {
                  this.msgSuccess("通知成功！");
                  this.findMoreProductItemPageFn();
                }
              });
            }else{
              this.msgWarn("已通知！");
              return;
            }
           
        },
        teacherTZ(row){
          // var date1=new Date(this.tSearchTime) //要对比的时间
          // var date2=new Date(); //获取当前时间对象
          // var num=24*60*60*1000 ; //一天的毫秒数
          // var cha=date2.getTime()-date1.getTime(); //两个时间的毫秒差
          // if(cha>0){
          // if(cha>num){
          //   //不能通知
          //   return false;
          // }else {
          //   return true
          // }
          // }else if(cha<0){
          // return true
          // }
        },
        sureClass(num,row){
          let curTime = new Date(row.beginTime);
          let addHour = curTime.setHours(
            curTime.getHours() + Number(row.usedClassHour)
          );
          let newDate = Date.parse(new Date()); //现在的时间
          if (newDate < addHour) {
            this.msgWarn("请在课程结束后确认课时！");
            return;
          }
          if (num == 1) {
            //确认课时
            confirmClassHour({
              id: row.id,
              usedStatus: "1",
              attendClassStatus: "1",
            }).then((res) => {
              this.findMoreProductItemPageFn();
              if (res.code == 0) {
              }
            });
          } else {
          }
        },
        recoveryCourse(row){
          let startTime= new Date(row.startTime);//开始上课时
          startTime.setHours(startTime.getHours()+Number(row.usedClassHour));
          let endTime= new Date(row.endTime).getTime();//结束上课时间
            resumeClass({
              id: row.id,
            }).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("恢复课程成功！");
                this.findMoreProductItemPageFn();
              }
            });
        },
        removeClass(row){
          if(row.attendClassStatus == 1 || row.attendClassStatus == 2){
            return this.msgWarn('只有未上课的才能删除！')
          }
          this.$confirm("确定要删除吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              deleteLiveBroadcastClass({id:row.id}).then((res) => {
                if (res.code == 0) {
                  this.msgSuccess("操作成功！");
                  this.findMoreProductItemPageFn();
                }
              });
            })
            .catch(() => {});
        },
        recoveryType(row){
           let startTime= new Date(row.startTime);//开始上课时
          startTime.setHours(startTime.getHours()+Number(row.usedClassHour));
          if(new Date().getTime()>new Date(startTime).getTime()){
            return true;
          }else{
            return false;
          }
        },
        getMsgFormSon(data){
        this.Visible=false;
        // this.$set(this.teacherList,this.visibleIndex,this.teacherList[this.visibleIndex])
      },
        getSubjectList(){
            getSelectList({
                parentCode: subjectCode,
            }).then((res) => {
                if (res.code == 0) {
                this.subjectIdList = res.rows;
                this.subjectIdList.unshift({
                    macroId: "",
                    name: "全部",
                });
                }
            });
        },
        handleSizeChange(pageSize){
          this.listParams.pageSize = pageSize;
          this.findMoreProductItemPageFn();
        },
        handleCurrentChange(currentPage){
          this.listParams.pageNumber = currentPage;
          this.findMoreProductItemPageFn();
        },
        formatterAttendClassStatus(row){
            switch (row.attendClassStatus) {
                case 0:
                 return  "未上课";
                case 1:
                 return "已上课";
                case 2:
                 return "上课中";
                default:
                    return "";
            }
        },
        datePickerChange() {
            if (!this.timePickerValue) {
                this.listParams.searchBeginDate = null;
                this.listParams.searchEndDate = null;
                return;
            }
            this.listParams.searchBeginDate = this.timePickerValue[0];
            this.listParams.searchEndDate = this.timePickerValue[1];
        },
    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
      
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .classInfo_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .classInfo_ul li {
    width: 25%;
    margin-top: 20px;
    font-size: 16px;
  }
  .subject_ul {
    width: 100%;
    overflow: visible;
    height: 55px;
    line-height: 55px;
  }
  .subject_ul li {
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    float: left;
  }
}
 .subjectActive {
    overflow: hidden;
  }
  .teachContent{
    display: flex;
    flex-direction: column;
    
  }
   .infoDetails{
    height:150px;
    padding-top:20px;
    display: flex;
  }
  .infoDetails .left{
    display: flex;
    width:35%;
    min-width:500px;
  }
  .infoDetails .content{
      width:350px;
  }
  .infoDetails .content ul{
    margin-top:30px;
  }
  .infoDetails .content ul li{
    margin-top:5px;
  }
  .infoDetails .content ul span:nth-child(1){
    font-size:15px;
    color:#414a60;
  }
  .infoDetails .content ul span:nth-child(2){
    font-size:15px;
    color:#61687c;
  }
  .infoDetails .right{
    display: flex;
    width:20%;
    justify-content: center;
    align-items: center;
  }
</style>