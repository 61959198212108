<template>
    <div>
        <el-dialog
            title="购买学员详情"
            center
            width="800px"
            :close-on-click-modal="false"
            @close="closeDialog"
            close-on-press-escape
            :visible.sync="buyPersonsVisible"
        >
            <div class="dialog-contents">
                <div class="table-area">
                    <el-table
                        size="small"
                        :data="dataList"
                        style="width: 100%;margin-top:10px;"
                        row-key="roleId"
                        border
                        tooltip-effect="dark"
                        ref="customDataTable"
                    >
                    <el-table-column
                        type="index"
                        label="编号"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="realName"
                        label="学员姓名"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="sectionName"
                        label="年级"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="basicPrice"
                        label="电话"
                        align="center"
                    >
                        <template slot-scope="scope">
                            {{scope.row.authorizedTelephone?scope.row.authorizedTelephone:''}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="basicPrice"
                        label="付款渠道"
                        align="center"
                    >
                        <template slot-scope="scope">
                            {{scope.row.type==1?'后台':scope.row.type==2?'小程序':''}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop=""
                        label="停留总时长"
                        align="center"
                    >
                        <template slot-scope="scope">
                          <div @click="stopTimeClick(scope.row)" style="cursor:pointer;text-decoration:underline;" > {{formatSeconds(scope.row.totalLengthOfStay)}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="numberOfEntries"
                        label="进入次数"
                        align="center"
                    >
                    </el-table-column>
                    </el-table>
                </div>
                <div class="page-area">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="listParams.pageNumber"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="listParams.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                        background
                    ></el-pagination>
                </div>
            </div>
        </el-dialog>
        <el-dialog
            title="停留详情"
            center
            width="800px"
            :close-on-click-modal="false"
            close-on-press-escape
            :visible.sync="stopVisible"
        >
            <div class="dialog-contents">
                <div class="table-area">
                    <el-table
                        size="small"
                        :data="stopTimeData"
                        style="width: 100%;margin-top:10px;"
                        row-key="roleId"
                        border
                        tooltip-effect="dark"
                        ref="customDataTable"
                    >
                    <el-table-column
                        prop="realName"
                        label="学员姓名"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="sectionName"
                        label="进入时间"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop=""
                        label="退出时间"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="numberOfEntries"
                        label="时长"
                        align="center"
                    >
                    </el-table-column>
                    </el-table>
                </div>
                <div class="page-area">
                    <el-pagination
                        @size-change="stopSizeChange"
                        @current-change="stopCurrentChange"
                        :current-page="stopParams.pageNumber"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="stopParams.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="stopTimeTotal"
                        background
                    ></el-pagination>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
  getWechatProductUserList,
  getWechatProductUserTimeList,//停留详情列表
} from "@/api/operates/productManage";
export default {
    props:["buyPersonsStatus","buyPersonsId"],
    watch:{
        buyPersonsStatus(newValue,oldValue){
            this.buyPersonsVisible=newValue;
            if(newValue==true){
                this.listParams.productId=this.buyPersonsId;
                this.getWechatProductUserListFn();
            }
        }
    },
    data(){
        return{
            buyPersonsVisible:false,
            dataList:[],
            total:0,
            stopVisible:false,
            stopTimeData:[],
            stopTimeTotal:0,
            listParams:{
                pageNumber:1,
                pageSize:10,
                type:"1,2",
                productId:""
            },
            stopParams:{
                pageNumber:1,
                pageSize:10,
                userId:""
            },
        }
    },
    methods:{
        getWechatProductUserListFn(){
            getWechatProductUserList(this.listParams).then((res) => {
                    this.dataList=res.rows;
                    this.total=res.total;
            });
        },
        stopTimeClick(row){
            this.stopVisible=true;
            this.stopParams.userId=row.id;
            this.getWechatProductUserTimeListFn();
        },
        handleSizeChange(pageSize){
            this.listParams.pageSize = pageSize;
            this.getWechatProductUserListFn();
        },
        handleCurrentChange(currentPage){
            this.listParams.pageNumber=currentPage;
            this.getWechatProductUserListFn();
        },
        getWechatProductUserTimeListFn(){
            getWechatProductUserTimeList(this.stopParams).then((res) => {
                this.stopTimeData=res.rows;
                this.stopTimeTotal=res.total;
            });
        },
        stopSizeChange(pageSize){
            this.stopParams.pageSize = pageSize;
            this.getWechatProductUserTimeListFn();
        },
        stopCurrentChange(currentPage){
            this.stopParams.pageNumber=currentPage;
            this.getWechatProductUserTimeListFn();
        },
        formatSeconds(value){
            var secondTime = parseInt(value);// 秒
            var minuteTime = 0;// 分
            var hourTime = 0;// 小时
            if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
                //获取分钟，除以60取整数，得到整数分钟
                minuteTime = parseInt(secondTime / 60);
                //获取秒数，秒数取佘，得到整数秒数
                secondTime = parseInt(secondTime % 60);
                //如果分钟大于60，将分钟转换成小时
                if(minuteTime > 60) {
                    //获取小时，获取分钟除以60，得到整数小时
                    hourTime = parseInt(minuteTime / 60);
                    //获取小时后取佘的分，获取分钟除以60取佘的分
                    minuteTime = parseInt(minuteTime % 60);
                }
            }
            var result = "" + parseInt(secondTime) + "秒";
            if(minuteTime > 0) {
                result = "" + parseInt(minuteTime) + "分" + result;
            }
            if(hourTime > 0) {
                result = "" + parseInt(hourTime) + "小时" + result;
            }
            return result;
        },
        closeDialog(){
            this.$emit('CB_buyPersonStatus')
        }
    }
}
</script>
<style scoped lang="scss">
    .stuInfo{
        display: flex;
        font-size:16px;
        span:nth-child(2){
            margin-left:60px;
        }
    }
    .seePrice-div{
      font-size:15px;
      padding-bottom:30px;
      ul{
        margin-top:20px;
        li{
          line-height: 26px;
        }
      }
    }
    .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-contents{
    display: flex;
    flex-direction: column;
    height:100%;
    .btn-div{
      height:30px;
      float:right;
      margin-top:20px;
    }
    .table-area{
      flex:1;
      overflow: hidden;
    }
  }
    
</style>
