<template>
    <div>
        <el-dialog
            title="会员列表"
            center
            width="800px"
            :close-on-click-modal="false"
            @close="closeDialog"
            :visible.sync="memberNewVisible"
        >
            <div class="dialog-contents">
                <div class="table-area">
                    <el-table
                        size="small"
                        :data="dataList"
                        style="width: 100%;margin-top:10px;"
                        row-key="roleId"
                        border
                        tooltip-effect="dark"
                        ref="customDataTable"
                    >
                    <el-table-column
                        prop="openId"
                        label="小程序ID"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="realName"
                        label="学员姓名"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="sectionName"
                        label="年级"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="subjectId"
                        label="想听科目"
                        align="center"
                        :formatter="dealSubjectIdd"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="authorizedTelephone"
                        label="电话"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="basicPrice"
                        label="停留总时长"
                        align="center"
                    >
                        <template slot-scope="scope">
                           {{formatSeconds(scope.row.totalLengthOfStay)}}
                        </template>
                    </el-table-column>
                     <el-table-column
                        prop="numberOfEntries"
                        label="进入次数"
                        align="center"
                    >
                    </el-table-column>
                    </el-table>
                </div>
                <div class="page-area">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="listParams.pageNumber"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="listParams.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                        background
                    ></el-pagination>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
  getWechatProductUserList
} from "@/api/operates/productManage";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  achievement,//擅教学生
  teachStyle,//讲课风格
  readingStage,//在读阶段
  aisciplineProfessorSection,//学科类--教授学段
  disciplineProfessorGrade,//学科类--教授年级
  subjectTeachingSubject,//学科类--教学科目
  qualityClassProfessorSection,//素质类--教学学段
  qualityClassTeachingGrade,//素质类--教学年级
  qualityTeachingSubjects,
  qualityStudentsType,//学生类型

} from "@/api/public";
export default {
    props:["memberNewStatus","memberNewId"],
    watch:{
        memberNewStatus(newValue,oldValue){
            this.memberNewVisible=newValue;
            if(newValue==true){
                this.listParams.productId=this.memberNewId;
                this.getWechatProductUserListFn();
            }
        }
    },
    data(){
        return{
            memberNewVisible:false,
            dataList:[],
            total:0,
            subjectList:[],
            listParams:{
                pageNumber:1,
                pageSize:10,
                type:"3",
                productId:""
            }
        }
    },
    created(){
        this.getSubjectList();
    },
    methods:{
        getWechatProductUserListFn(){
            getWechatProductUserList(this.listParams).then((res) => {
                    this.dataList=res.rows;
                    this.total=res.total;
            });
        },
        handleSizeChange(pageSize){
            this.listParams.pageSize = pageSize;
            this.getWechatProductUserListFn();
        },
        handleCurrentChange(currentPage){
            this.listParams.pageNumber=currentPage;
            this.getWechatProductUserListFn();
        },
        getSubjectList() {
            return getSelectList({
                parentCode: subjectCode
            }).then(res => {
                if (res.code == 0) {
                this.subjectList = res.rows;
                }
            });
        },
        dealSubjectIdd(row) {
            let result = []
            let list=this.subjectList;
            var subjectArray=row.subjectId?row.subjectId.split(','):[];
            for(let  i=0;i<list.length;i++){
                let tempArr1=list[i];
                for(let j=0;j<subjectArray.length;j++){
                let tempArr2=subjectArray[j]
                if(tempArr2==tempArr1.macroId){
                    result.push(tempArr1.name)
                    break;
                }
                }
            }
            return result.join();
        },
        closeDialog(){
            this.$emit("CB_memberNewStatus")
        },
        formatSeconds(value){
            var secondTime = parseInt(value);// 秒
            var minuteTime = 0;// 分
            var hourTime = 0;// 小时
            if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
                //获取分钟，除以60取整数，得到整数分钟
                minuteTime = parseInt(secondTime / 60);
                //获取秒数，秒数取佘，得到整数秒数
                secondTime = parseInt(secondTime % 60);
                //如果分钟大于60，将分钟转换成小时
                if(minuteTime > 60) {
                    //获取小时，获取分钟除以60，得到整数小时
                    hourTime = parseInt(minuteTime / 60);
                    //获取小时后取佘的分，获取分钟除以60取佘的分
                    minuteTime = parseInt(minuteTime % 60);
                }
            }
            var result = "" + parseInt(secondTime) + "秒";
            if(minuteTime > 0) {
                result = "" + parseInt(minuteTime) + "分" + result;
            }
            if(hourTime > 0) {
                result = "" + parseInt(hourTime) + "小时" + result;
            }
            return result;
        },
    }
}
</script>

<style scoped lang="scss">
    .stuInfo{
        display: flex;
        font-size:16px;
        span:nth-child(2){
            margin-left:60px;
        }
    }
    .seePrice-div{
      font-size:15px;
      padding-bottom:30px;
      ul{
        margin-top:20px;
        li{
          line-height: 26px;
        }
      }
    }
    .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-contents{
    display: flex;
    flex-direction: column;
    height:100%;
    .btn-div{
      height:30px;
      float:right;
      margin-top:20px;
    }
    .table-area{
      flex:1;
      overflow: hidden;
    }
  }
    
</style>
