<template>
    <el-dialog
        title="选择讲师"
        center
        width="1000px"
        close-on-press-escape
        :visible.sync="Visible"
       @close="$emit('close')"
        :close-on-click-modal="false"
    >
            <div class="dialog-content">
                <div class="btn-area select-condition">
                    <el-menu
                    :default-active="teacherIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="teacherIndexSelect"
                    >
                    <el-menu-item index="1">全部讲师</el-menu-item>
                    <el-menu-item index="2">关注讲师</el-menu-item>
                    <el-menu-item index="7">常用讲师</el-menu-item>
                    </el-menu>
                </div>
                <div class="btn-area select-condition" >
                    <div class="left">
                        <div class="select-condition-item">
                        <div class="select-condition-item"> 
                            <span class="item-lable">关键字：</span>
                            <el-input
                            v-model="listParams.name"
                            placeholder="姓名/联系方式"
                            size="small"
                            ></el-input>
                        </div>
                        <div class="select-condition-item">
                            <span class="item-lable">年级：</span>
                            <el-select
                            v-model="listParams.sectionId"
                            placeholder="请选择"
                            size="small"
                            >
                            <el-option
                                v-for="item in sectionIdList"
                                :key="item.macroId"
                                :label="item.name"
                                :value="item.macroId"
                            ></el-option>
                            </el-select>
                        </div>
                        <div class="select-condition-item">
                            <span class="item-lable">学科：</span>
                            <el-select
                            v-model="listParams.subjectId"
                            placeholder="请选择"
                            size="small"
                            >
                            <el-option
                                v-for="item in subjectIdList"
                                :key="item.macroId"
                                :label="item.name"
                                :value="item.macroId"
                            ></el-option>
                            </el-select>
                        </div>
                        <div class="select-condition-item">
                            <span class="item-lable">讲师身份：</span>
                            <el-select
                            v-model="listParams.identityType"
                            placeholder="请选择"
                            size="small"
                            >
                            <el-option
                                v-for="item in identityTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                            </el-select>
                        </div>
                        <div class="select-condition-item">
                            <span class="item-lable">讲师职称：</span>
                            <el-select
                            v-model="listParams.teachTitleId"
                            placeholder="请选择"
                            size="small"
                            >
                            <el-option
                                v-for="item in teachTitleIdList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                            </el-select>
                        </div>
                        </div>
                        <div class="select-condition-item">
                        <el-button type="primary" size="small" icon="el-icon-search"
                            @click="listTeacherManager()"
                            >搜索</el-button
                        >
                        </div>
                    </div>
                </div>
                <div class="table-area">
                    <el-table
                        size="small"
                        :data="teacherList"
                        style="width: 100%; margin-bottom: 20px"
                        border
                        row-key="id"
                        tooltip-effect="dark"
                        ref="teacherListTable"
                        @selection-change="handleSelectionChange"
                        @row-click="teacherItemClick"
                    >
                    <el-table-column type="selection" width="55" align="center" />
                        <el-table-column prop="name" label="">
                        <template slot-scope="scope">
                            <div class="teachContent">
                            <div class="infoDetails">
                                <dl class="left">
                                <dt><img :src="scope.row.photo" alt="" style="width:100px;height:100px;background-size:cover;"> </dt>
                                <dd style="margin-left:35px;">
                                    <el-tooltip
                                      effect="dark"
                                      :content="aa + getNum + bb"
                                      placement="top"
                                      >
                                      <p
                                          @mouseenter="seeMany(scope.row)"
                                          style="display:inline-block;font-size:16px;cursor:pointer;color:#333;"
                                      >
                                          {{ scope.row.name?scope.row.name:'暂无昵称' }}<span style="font-size:14px;margin-left:5px;">（{{scope.row.realname}}）</span><span style="font-size:14px;margin-left:10px;">{{onLineFlagFn(scope.row.onLineFlag)}}</span>
                                      </p>
                                </el-tooltip>
                                <p class="info_p">
                                  <span>{{scope.row.sexId==1?'男':scope.row.sexId==2?'女':'暂无性别'}}</span>
                                  <!-- <span>28岁</span> -->
                                  <span>{{scope.row.isEquipment==1?'有设备':'无设备'}}</span>
                                  <span>{{scope.row.teachingDirection==1?'学科类':'素质教育类'}}</span>
                                </p>
                                <p style="margin-top:10px;">
                                <el-tag size='small' type="warning" v-for="(item,key) in scope.row.arr" :key='key'>{{item.name}}</el-tag>
                                </p>
                                <p style="margin-top:10px;" >
                                    分组：
                                    <span v-show="scope.row.concernType==0">未关注</span>
                                    <span v-show="(scope.row.concernType==1||scope.row.concernType==3)&&scope.row.groupName==null">未分组</span>
                                    <span v-show="scope.row.groupName">{{ scope.row.groupName}}</span>
                                </p>
                                </dd>
                                </dl>
                                <div class="content">
                                    <ul>
                                        <li><span>授课方式：</span><span>{{classFormFn(scope.row.classForm)}}</span></li>
                                        <li><span>擅教学生：</span><span  >{{judgeStyleNeedFn(scope.row.skilfulTeach)}}</span></li>
                                        <li><span>讲课风格：</span><span  >{{skilfulFn(scope.row.skilful)}}</span></li>
                                    </ul>
                                </div>
                            </div>  
                            </div>
                        </template>
                        </el-table-column>
                    </el-table>
                    </div>
              <div class="page-area">
                <el-pagination
                  @size-change="teacherSizeChange"
                  @current-change="teacherCurrentChange"
                  :current-page="listParams.pageNumber"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="listParams.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                  background
                ></el-pagination>
            </div>
            </div>
            <span slot="footer" class="dialog-footer" v-show="showType==1">
              <el-button type="primary" @click="saveTeacherData(1)" size="small"
                >添加配置讲师</el-button
              >
              <el-button type="success" @click="saveTeacherData(2)" size="small"
                >添加预配置讲师</el-button
              >
              <el-button @click="$emit('close')" size="small">取 消</el-button>
          </span>
          <span slot="footer" class="dialog-footer" v-show="showType==2">
              <el-button @click="$emit('close')" size="small"
                >取 消</el-button
              >
              <el-button type="primary" size="small" @click="sureTeacher()"
                >确 定</el-button
              >
          </span>
    </el-dialog>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  constellation,
  teachStyle,
  teachYear,
  teachSpeed,
  reg_Num,
  followTags,
  resultCode,
  teachAge,
  mandarinCode,
  achievement,
} from "@/api/public";
import {
  listTeacherManager,
  countConcern
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
export default {
    props:["Visible","visibleIndex","visibelList","type"],
    data(){
        return{
            teacherIndex:"1",
            handleKey:1,
            sectionIdList:[],
            teacherList:[],
            total:0,
            getNum: "",
            aa: "已有",
            bb: "名教务关注了该讲师",
            listParams: {
                hopeFee: "",
                name: "", //关键字
                sectionId: "", //年级
                subjectId: "", //科目
                identityType: "", //讲师身份
                teachTitleId: "", //讲师职称
                pageNumber: 1,
                pageSize: 10,
            },
            subjectIdList:[],
            identityTypeList:[
                {
                    value: "",
                    label: "全部",
                },
                {
                    value: 1,
                    label: "学科类-老师",
                },
                {
                    value: 2,
                    label: "学科类-在校大学生",
                },
                {
                    value: 3,
                    label: "素质教育类-老师",
                },
                {
                    value: 4,
                    label: "素质教育类-在校大学生",
                }
            ],
            teachTitleIdList: [
                {
                    value: "",
                    label: "全部",
                },
                {
                    value: 59,
                    label: "初级讲师",
                },
                {
                    value: 60,
                    label: "中级讲师",
                },
                {
                    value: 61,
                    label: "高级讲师",
                },
                {
                    value: 62,
                    label: "特级讲师",
                },
            ],
            getval:[],
            tableRadio:"",
            checkedGhCheck:[],
            showType:"",
            styleNeedArray:[],
            teachStyleArray:[]
        }
    },
    created(){
      
    },
    mounted(){
        this.getSectionIdList();//年级
        this.getSubjectList();//学科
        this.getSelectList();//风格偏好
        this.listTeacherManager();
        if(this.type==1){//配置老师
          this.showType=1;
        }else if(this.type==2){//排课选择老师
          this.showType=2
        }

    }, 
    methods:{
        teacherIndexSelect(key,keyPath){
            this.handleKey=key;
            this.listParams= {
                hopeFee: "",
                name: "", //关键字
                sectionId: "", //年级
                subjectId: "", //科目
                identityType: "", //讲师身份
                teachTitleId: "", //讲师职称
                pageNumber: 1,
                pageSize: 10,
            },
            this.listTeacherManager();
        },
        sureTeacher(){
          // this.$emit('callBackTea',this.tableRadio)
        },
        getSelectList(){
           getSelectList({
              //风格偏好
              parentCode: achievement,
            }).then((res) => {
              if (res.rows) {
                this.styleNeedArray = res.rows;
              }
            });
             getSelectList({
                //讲课风格
                parentCode: teachStyle,
              }).then((res) => {
                if (res.rows) {
                  this.teachStyleArray = res.rows;
                }
              });
        },
        judgeStyleNeedFn(item) {
          let arr = new Array();
          let str = "";
          if(item){
            arr = item.split(",");
          }
          for (var i = 0; i < this.styleNeedArray.length; i++) {
              for (var k = 0; k < arr.length; k++) {
                if(this.styleNeedArray[i].macroId==arr[k]){
                    str+=this.styleNeedArray[i].name.split(":")[0]+"，"
                }
              }
          }
          return str.substring(str.length-1,0);
        },
        skilfulFn(item) {
          let arr = new Array();
          let str = "";
          if(item){
            arr = item.split(",");
          }
          for (var i = 0; i < this.teachStyleArray.length; i++) {
              for (var k = 0; k < arr.length; k++) {
                if(this.teachStyleArray[i].macroId==arr[k]){
                    str+=this.teachStyleArray[i].name.split("：")[0]+"，"
                }
              }
          }
          return str.substring(str.length-1,0);
        },
        listTeacherManager() {
            let data = {
                selectType:this.handleKey==1?"":this.handleKey==2?'2':'7',
                name: this.listParams.name,
                sectionId:
                this.listParams.sectionId == "0" ? "" : this.listParams.sectionId, //年级
                subjectId:
                this.listParams.subjectId == "0" ? "" : this.listParams.subjectId, //学科
                identityType:
                this.listParams.identityType == "0"
                    ? null
                    : this.listParams.identityType, //身份
                teachTitleId:
                this.listParams.teachTitleId == "0"
                    ? null
                    : String(this.listParams.teachTitleId), //职称
                pageNumber: this.listParams.pageNumber,
                pageSize: this.listParams.pageSize
            };
            listTeacherManager(data).then(res => {
                this.teacherList=res.rows;
                this.total = res.total;
            });
        },
        teacherSizeChange(pageSize){
          this.listParams.pageSize = pageSize;
          this.listTeacherManager()
        },
        teacherCurrentChange(currentPage){
          this.listParams.pageNumber = currentPage;
          this.listTeacherManager()
        },
        saveTeacherData(num){
          if (this.getval.length == 0) {
            this.msgWarn("请选择讲师!");
            return;
          }
          if(num==1){
            this.visibelList[this.visibleIndex].label = 2;
          }else {
            this.visibelList[this.visibleIndex].label = 1;
          }
          this.visibelList[this.visibleIndex].teacherRealname=this.tableRadio.realname;
          this.visibelList[this.visibleIndex].oldTeaChatId=this.visibelList[this.visibleIndex].teaChatId;
          this.visibelList[this.visibleIndex].newTeaChatId=this.tableRadio.teaChatId;
          this.visibelList[this.visibleIndex].teacherId=this.tableRadio.id;
          this.visibelList[this.visibleIndex].hopeMinFee=this.tableRadio.hopeMinFee;
          this.visibelList[this.visibleIndex].hopeMaxFee=this.tableRadio.hopeMaxFee;
          this.visibelList[this.visibleIndex].show=true;
          this.$emit('func',this.visibelList)
        },
        handleSelectionChange(val){
          this.getval = val;
          this.tableRadio = val[0];
          this.checkedGhCheck = val[0].id;
          if (val.length > 1) {
            this.$refs.teacherListTable.clearSelection();
            this.$refs.teacherListTable.toggleRowSelection(val.pop());
          }
        },
        teacherItemClick(row){
          this.$refs.teacherListTable.toggleRowSelection(row);
        },
        seeMany(row){
          countConcern({ teacherId: row.id }).then((res) => {
            if (res.code == 0) {
              this.getNum = res.msg;
            }
          });
        },
        getSubjectList() {
            getSelectList({
                parentCode: subjectCode,
            }).then((res) => {
                if (res.code == 0) {
                this.subjectIdList = res.rows;
                this.subjectIdList.unshift({
                    macroId: "",
                    name: "全部",
                });
                }
            });
        },
        getSectionIdList() {
            getSelectList({
                parentCode: sectionCode,
            }).then((res) => {
                if (res.code == 0) {
                this.sectionIdList = res.rows;
                this.sectionIdList.unshift({
                    macroId: "",
                    name: "全部",
                });
                }
            });
        },
        titleFn(row) {
            switch (row) {
                case 59:
                return "初级教师";
                case 60:
                return "中级教师";
                case 61:
                return "高级教师";
                case 62:
                return "特级教师";
                default:
                return "";
            }
        },
         onLineFlagFn(num){
            switch(num){
                case 1:
                return '在线'
                case 2:
                return '刚刚活跃'
                case 3:
                return '一天前活跃'
                case 4:
                return '七天前活跃'
                default:
                return ''
            }
        },
        closeDialog(){
          this.$emit('CB_selectStatus')
        },
        identityFn(row) {
            for(var i=0;i<this.identityTypeList.length;i++){
                if(this.identityTypeList[i].value==row){
                    return this.identityTypeList[i].label;
                }
            }
        },
        classFormFn(type) {
            if (type == "1") {
                return "线上";
            } else if (type == "2") {
                return "线下";
            } else if (type == "0") {
                return "均可";
            }
        },
    },
    
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .studentInfo{
    h2{
      font-size:16px;
      margin-top:20px;
    }
    ul{
      display: flex;
      flex-wrap:wrap;
      margin-top:10px;
      li{
        width:33.3%;
        padding:5px 0;
      }
    }
  }
  .infoRecord{
    display: flex;
    width:100%;
    span{
      width:25%;
      display: inline-block;
      padding:5px 0;
    }
  }
}
.teachContent {
  display: flex;
  flex-direction: column;
}
.infoDetails {
  height: 150px;
  padding-top: 20px;
  display: flex;
}
.infoDetails .left {
  display: flex;
  width: 35%;
  min-width: 500px;
}
.infoDetails .content {
  width: 350px;
}
.infoDetails .content ul {
  margin-top: 30px;
}
.infoDetails .content ul li {
  margin-top: 5px;
}
.infoDetails .content ul span:nth-child(1) {
  font-size: 14px;
  color:#909399;
}
.infoDetails .content ul span:nth-child(2) {
  font-size:14px;
    color:#333;
}

.info_p{
    color:#333;
    margin-top:13px;
    span{
      margin-right:10px;
    }
  }
</style>