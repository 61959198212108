<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="oneToMoreParams.name"
            placeholder="老师姓名/学生姓名"
            size="small"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="oneToMoreParams.sectionId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">学科：</span>
          <el-select
            v-model="oneToMoreParams.subjectId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in subjectIdList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue2"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange2"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          @click="
            () => ((oneToMoreParams.pageNumber = 1), getOneToMorePlaybackCoursePageFn())
          "
          icon="el-icon-search"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="oneToMoreData"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        height="100%"
        ref="oneToMoreData"
        tooltip-effect="dark"
      >
        <el-table-column prop="roomName" label="学生人数" align="center">
          <template slot-scope="scope">
            {{ scope.row.studentList.length }}
          </template>
        </el-table-column>
        <el-table-column
          prop="teacherRealName"
          label="老师姓名"
          align="center"
        ></el-table-column>
        <el-table-column prop="sectionName" label="年级" align="center"></el-table-column>
        <el-table-column prop="subjectName" label="学科" align="center"></el-table-column>
        <el-table-column label="时间" align="center" prop="createTime">
          <template slot-scope="scope">
            {{ scope.row.beginTime.substring(0, 16) }}-{{
              scope.row.stopTime.split(" ")[1].substring(0, 5)
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="usedClassHour"
          label="课时"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          label="班级名称"
          prop="roomName"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openLook(scope.row)"
              >在线打开</el-button
            >
            <el-button type="text" size="small" @click="seeQrcode(scope.row)"
              >扫码观看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="moreHandleSizeChange"
        @current-change="moreHandleCurrentChange"
        :current-page="oneToMoreParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="oneToMoreParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalMore"
        background
      ></el-pagination>
    </div>
    <el-dialog
      title=""
      :visible.sync="qrcodePopup"
      :close-on-click-modal="false"
      width="710px"
      center
    >
      <div class="dialog-content" style="padding-bottom: 15px">
        <ul class="qrCodeInfo">
          <li>学生姓名：{{ qrCodeParams.studentName }}</li>
          <li>老师姓名：{{ qrCodeParams.teacherRealName }}</li>
          <!-- <li>学科：{{qrCodeParams.subjectName}}</li> -->
          <li>时间：{{ qrCodeParams.time }}</li>
        </ul>
        <div style="margin: 10px auto" class="qrcodeAllDiv" v-show="videoUrl">
          <p v-for="(item, index) in videoUrl" :key="index">
            <vue-qr ref="QrAllcode" :text="item" :callback="callbackFn" qid="testQrId">
            </vue-qr>
            <a
              :href="exportLink"
              @click="downloadImg(index)"
              :download="downloadFilename"
              style="
                color: #1890ff;
                text-decoration: none;
                display: block;
                text-align: center;
              "
              >下载二维码</a
            >
          </p>
        </div>
        <div style="margin: 10px auto" class="qrcodeAllDiv" v-show="videoUrlValid">
          <p v-for="(item, index) in videoUrlValid" :key="index">
            <img :src="item" alt="" />
            <!-- <a :href="exportLink" @click="downloadImg(index)" :download="downloadFilename" style="color:#1890FF;text-decoration:none;display:block;text-align:center;">下载二维码</a> -->
          </p>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="在线打开"
      :visible.sync="openLookShow"
      :close-on-click-modal="false"
      width="1000px"
      center
      @close="cloneVideo()"
    >
      <div class="dialog-content">
        <div class="videoDiv">
          <video
            controls="controls"
            class="video"
            v-for="(item, index) in openArray"
            :key="index"
            :src="item"
          ></video>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import dayjs from "dayjs";
import VueQr from "vue-qr";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  reg_Num,
} from "@/api/public";
import {
  getPlaybackCoursePage,
  listNoPage,
  getRecordingUrlByItemId, //扫码观看
  getOneToMorePlaybackCoursePage,
  saveCallBackQrCode, //存储回放二维码
  getAddressIp, //获取域名
} from "@/api/administration/tubeTeachBusiness/studentManage.js";
import {
  fileUploads
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
export default {
  components: { VueQr },
  data() {
    return {
      addressIp:"",
      totalMore: 0,
      saleList: [],
      saleParams: {
        roleSign: "studentAdmin",
        status: 1,
        roleSignAdmin: "studentAdminAdmin",
      },
      timePickerValue: "",
      openLookShow: false,
      exportLink: "",
      downloadFilename: "",
      qrcodePopup: false,
      dataObj: {
        text: "",
      },
      activeIndex: "1",
      handleKey: 1,
      sectionList: [],
      subjectIdList: [],
      qrCodeParams: {
        studentName: "",
        teacherRealName: "",
        subjectName: "",
        time: "",
      },
      timePickerValue2: "",

      oneToMoreParams: {
        name: "",
        sectionId: "",
        subjectId: "",
        pageNumber: 1,
        pageSize: 10,
        searchBeginDate: null,
        searchEndDate: null,
        dataAuthority: "user",
      },
      oneToOneData: [],
      oneToMoreData: [],
      mergeShow: false,
      videoUrl: [],
      videoUrlValid: [],
      openArray: [],
      total: 0,
      urlArray: [],
      callParams: {
        itemId: "",
        qrCode: "",
      },
      playUrl:`${process.env.VUE_APP_PUBLIC_BASE_URL}/playback.html?playBackUrl=&playBackUrl=`,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs().subtract(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(90, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    this.timePickerValue = [
      year + "-" + month + "-" + day + " 00:00:00",
      year + "-" + month + "-" + day + " 23:59:59",
    ];

    //一对多
    this.timePickerValue2 = [
      year + "-" + month + "-" + day + " 00:00:00",
      year + "-" + month + "-" + day + " 23:59:59",
    ];
    this.oneToMoreParams.searchBeginDate = this.timePickerValue2[0];
    this.oneToMoreParams.searchEndDate = this.timePickerValue2[1];
    this.getSaleList();
    this.getOneToMorePlaybackCoursePageFn();
  },
  methods: {
    getAddressIpFn(){
         getAddressIp().then((res) => {
           if(res.code==0){
            this.addressIp=res.rows;
           }
        });
      },
    getSaleList() {
      listNoPage(this.saleParams).then((res) => {
        this.saleList = res;
        this.saleList.unshift({
          userId: "",
          name: "全部",
          username: "全部",
        });
      });
    },

    moreHandleSizeChange(pageSize) {
      this.oneToMoreParams.pageSize = pageSize;
      this.getOneToMorePlaybackCoursePageFn();
    },
    moreHandleCurrentChange(currentPage) {
      this.oneToMoreParams.pageNumber = currentPage;
      this.getOneToMorePlaybackCoursePageFn();
    },
    datePickerChange2() {
      if (!this.timePickerValue2) {
        this.oneToMoreParams.searchBeginDate = null;
        this.oneToMoreParams.searchEndDate = null;
        return;
      }
      this.oneToMoreParams.searchBeginDate = this.timePickerValue2[0];
      this.oneToMoreParams.searchEndDate = this.timePickerValue2[1];
    },

    downloadImg(index) {
      this.exportLink = this.$refs.QrAllcode[index].$el.currentSrc;
      this.downloadFilename = "二维码";
    },
    openLook(row) {
      if (row.panoVideoUrlArray.length == 0) {
        this.msgWarn("视频正在生成中！");
        return;
      }
      this.openLookShow = true;
      this.openArray = [];

      getRecordingUrlByItemId({ itemId: row.id }).then((res) => {
        // this.openArray=row.panoVideoUrlArray;
        this.openArray = res.msg.panoVideoUrlArray;
        //  if(row.panoVideoUrlMerge){
        //     this.openArray.push(row.panoVideoUrlMerge);
        //   }else{
        //     this.openArray=row.panoVideoUrlArray;
        //   }
      });
    },
    async callbackFn(a, b) {
      var bytes = window.atob(a.split(",")[1]);
      var array = [];
      for (var i = 0; i < bytes.length; i++) {
        array.push(bytes.charCodeAt(i));
      }
      var blob = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
      var fd = new FormData();
      fd.append("file", blob, Date.now() + ".jpg");
      await fileUploads(fd).then((uploadRes) => {
        if (uploadRes[0].url) {
          this.urlArray.push(uploadRes[0].url);
        }
      });
      if (this.videoUrl.length == this.urlArray.length) {
        this.callParams.qrCode = this.urlArray.join(",");
        saveCallBackQrCode(this.callParams).then((res) => {
          if (res.code == 0) {
            this.msgSuccess("操作成功！");
            this.getOneToMorePlaybackCoursePageFn();
          }
        });
      }
    },
    async seeQrcode(row) {
      this.videoUrlValid = [];
      this.qrcodePopup = true;
      this.callParams.itemId = row.id;
      this.videoUrl = [];
      var oldtime = row.qrCodeTime,
        nowtime = new Date();
      let dateTime = "";
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      dateTime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      if (
        row.qrCodeTime == null ||
        nowtime.getTime() - new Date(oldtime).getTime() > 86400000
      ) {
        //有效期之外
        await getRecordingUrlByItemId({ itemId: row.id }).then((res) => {
          for (var i = 0; i < res.msg.panoVideoUrlArray.length; i++) {
            this.videoUrl.push(
              this.addressIp +
                "interface/xmtCommon/checkCallBackQrCode?itemId=" +
                row.id +
                "&qrCodeTime=" +
                dateTime +
                "&callBackUrl=" +
                this.playUrl +
                encodeURIComponent(res.msg.panoVideoUrlArray[i])
            );
          }
        });
      } else {
        //有效期内
        this.videoUrlValid = row.qrCode.split(",");
      }
      this.qrCodeParams = {
        studentName: row.studentName,
        teacherRealName: row.teacherRealName,
        subjectName: row.subjectName,
        time:
          row.beginTime.substring(0, 16) +
          "-" +
          row.stopTime.split(" ")[1].substring(0, 5),
      };
    },
    cloneVideo() {
      var myVideo = document.querySelectorAll(".video");
      for (var i = 0; i < myVideo.length; i++) {
        myVideo[i].pause();
      }
    },
    getOneToMorePlaybackCoursePageFn() {
      getOneToMorePlaybackCoursePage(this.oneToMoreParams).then((res) => {
        this.oneToMoreData = res.rows;
        this.totalMore = res.total;
      });
    },
    //获取年级
    getSectionIdList() {
      getSelectList({
        parentCode: sectionCode,
      }).then((res) => {
        if (res.code == 0) {
          this.sectionList = res.rows;
          this.sectionList.unshift({
            macroId: "",
            name: "全部",
          });
        }
      });
    },
    //获取学科
    getSubjectList() {
      getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
          this.subjectIdList = res.rows;
          this.subjectIdList.unshift({
            macroId: "",
            name: "全部",
          });
        }
      });
    },
  },
  mounted() {
    this.getSectionIdList(); //获取搜索年级
    this.getSubjectList(); //获取搜索学科
    this.getAddressIpFn()
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .classInfo_ul {
    display: flex;
    flex-wrap: wrap;
  }
  .classInfo_ul li {
    width: 25%;
    margin-top: 20px;
    font-size: 16px;
  }
  .subject_ul {
    width: 100%;
    overflow: visible;
    height: 55px;
    line-height: 55px;
  }
  .subject_ul li {
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    float: left;
  }
}
.subjectActive {
  overflow: hidden;
}
.qrcodeDiv {
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.qrcodeDiv img {
  margin: 0 auto;
}
.qrcodeAllDiv {
  display: flex;
  flex-wrap: wrap;
}
.qrCodeInfo {
  display: flex;

  font-size: 16px;
  li {
    flex: 1;
  }
}
.videoDiv {
  display: flex;
  flex-wrap: wrap;
  .video {
    width: 260px;
    margin: 20px;
  }
}
</style>
