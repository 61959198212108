<template>
    <div class="main-content sys-user-manage">
        <div class="btn-area">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="输入学生姓名/联系方式"
            size="small"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">添加时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panelsss
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button type="primary" size="small" icon="el-icon-search"
          @click="()=>(listParams.pageNumber=1,getProductList())"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="tableList"
        style="width: 100%; margin-bottom: 20px;margin-top:20px;"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
      >
       <el-table-column
          type="index"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="no"
          label="产品编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          align="center"
        ></el-table-column>
        <el-table-column 
          prop="createTime"
          label="添加时间"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          prop=""
          label="讲师"
          align="center"
        ></el-table-column> -->
        <el-table-column
          label="购买人数"
          align="center"
        >
          <template slot-scope="scope">
            {{scope.row.buyCount!=null?scope.row.buyCount:0}}
        </template>
        </el-table-column>
        <el-table-column
          prop="appletMembershipCount"
          label="会员人数"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="appletNewcomerCount"
          label="其他途径人数"
          align="center"
        ></el-table-column>
         <el-table-column
         :formatter="dealProductStatus"
          prop="educationalApprovalTime"
          label="产品状态"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="goWorkClick(scope.row)"
              >去排课</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
          <el-pagination
            @size-change="handleSizeCourseChange"
            @current-change="handleCourseChange"
            :current-page="listParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="listParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            background
          ></el-pagination>
        </div>
        <el-dialog
            title="公开课排课"
            center
            width="1000px"
            :close-on-click-modal="false"
            :visible.sync="workVisibleShow"
            
        >
            <div class="dialog-content">
                <div class="orderDetail">
                    <h2>产品信息</h2>
                    <ul>
                        <li><span>产品名称：</span><span>{{infoParams.productName}}</span></li>
                        <li><span>学段：</span><span>{{infoParams.sectionName}}</span></li>
                        <li><span>学科：</span><span>{{infoParams.subjectName}}</span></li>
                    </ul>
                    <p><span>课程简介：</span><span v-html="infoParams.introduce" style="display:inline-block;">{{infoParams.introduce}}</span> </p>
                </div>
                <div>
                    <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top:50px;">
                        <el-tab-pane label="配置讲师" name="first">
                            <ul class="subject_ul">
                                <li v-for="(item,index) in subjectIdList" :key="index" >
                                    <el-button size="small" type="primary"  @click="subjectClick(item.macroId)">{{item.name}}</el-button>
                                </li>
                            </ul>
                            <el-table
                                size="small"
                                :data="teacherList"
                                style="width: 100%; margin-top: 20px"
                                row-key="userId"
                                border
                                tooltip-effect="dark"
                            >
                                <el-table-column
                                    prop="label"
                                    label="标签"
                                    align="center"
                                >
                                    <template slot-scope="scope">
                                        {{scope.row.label==0?'无':scope.row.label==1?'预配置讲师':'配置讲师'}}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop=""
                                    label="学科"
                                    align="center"
                                    :formatter="dealSubjectId"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="teacherRealname"
                                    label="讲师"
                                    align="center"
                                >
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="200">
                                    <template slot-scope="scope">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="choiceTeacherClick(scope.$index)"
                                        >选老师</el-button>
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="setDispose(scope.$index)"
                                            v-show="scope.row.label == 1"
                                        >设为配置讲师</el-button>
                                        <el-button
                                          type="text"
                                          size="small"
                                          @click="sureTeacherData(scope, scope.$index)"
                                          v-show="scope.row.show == true"
                                          >确定</el-button
                                        >
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="removeTeacher(scope)"
                                        >删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="课程安排" name="second">
                          <div style="display:flex;">
                            <el-button type="primary" size="small" @click="singleWork"
                            
                              >单次排课</el-button
                            >
                          </div>
                          <el-table
                                size="small"
                                :data="courseList"
                                style="width: 100%; margin-top: 20px"
                                row-key="userId"
                                border
                                tooltip-effect="dark"
                            >
                                <el-table-column
                                    type="index"
                                    prop="label"
                                    label="序号"
                                    align="center"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="subjectName"
                                    label="学科"
                                    align="center"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="title"
                                    label="课程标题"
                                    align="center"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="beginTime"
                                    label="上课时间"
                                    align="center"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="usedClassHour"
                                    label="课时数"
                                    align="center"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="teacherRealName"
                                    label="讲师"
                                    align="center"
                                >
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="200">
                                    <template slot-scope="scope">
                                       <el-button
                                          type="text"
                                          size="small"
                                          @click="editClass(scope.row)"
                                          >修改</el-button
                                        >
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="removeClass(scope.row)"
                                        >删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </el-dialog>
        <selectTeacher ref="selectTeacher"  @func="getMsgFormSon" v-if="Visible" :Visible="Visible" :type="teacherType" :visibleIndex="visibleIndex" :visibelList="visibelList" @close="Visible=false"></selectTeacher>
        <arrangeClass ref="arrangeClass" :tableRadioInfo="tableRadioInfo" @callBackInfo="callBackInfo" :timeStatus="timeStatus" @CB_timeStatus="CB_timeStatus" :dataParams="infoParams"></arrangeClass>
        <selectTeacherClass ref="selectTeacherClass" @callBackTea="callBackTea" :classStatus="classStatus" @CB_classStatus="CB_classStatus" :params="classParams"></selectTeacherClass>
    </div>
</template>
<script>
import selectTeacher from "./dialog/selectTeacher.vue";
import arrangeClass from "./dialog/arrangeClass.vue";
import selectTeacherClass from "./dialog/selectTeacherClass.vue";
import {
  productList,//公开课列表
  configteacherPlanList,
  saveLiveBroadcastClass,//公开课排课保存
  findCourseItem,//公开课排课列表
  deleteLiveBroadcastClass,//公开课删除
} from "@/api/operates/productManage";
import {
  getSelectList,
  teachTitle,
  fileUpload,
  phaseCode,
  subjectCode,
} from "@/api/public";
import {deleteOneChooseTeacher,saveOneChooseTeacher} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
export default {
  components:{
    selectTeacher,
    arrangeClass,
    selectTeacherClass
  },
    data(){
        return{
            teacherType:"",
            timeStatus:false,
            classStatus:false,
            tableList:[],
            total:0,
            listParams: {
              name: "",
              videoType: '1',
              productStatus: "0",
              productType: 4,
              pageNumber: 1,
              pageSize: 10,
              searchBeginDate:null,
              searchEndDate:null,
            },
            timePickerValue:"",
            courseList:[],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                        const end = new Date(
                            dayjs()
                            .add(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        const start = new Date(
                            dayjs().format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "昨天",
                        onClick(picker) {
                        const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
                        const start = new Date(
                            dayjs()
                            .subtract(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近一周",
                        onClick(picker) {
                        const end = new Date(
                            dayjs()
                            .add(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        const start = new Date(
                            dayjs()
                            .subtract(7, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                        const end = new Date(
                            dayjs()
                            .add(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        const start = new Date(
                            dayjs()
                            .subtract(30, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                        const end = new Date(
                            dayjs()
                            .add(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        const start = new Date(
                            dayjs()
                            .subtract(90, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    }
                ]
            },
            infoParams:{},
            productStatusList: [
              {
                value: "0",
                label: "全部",
              },
              {
                value: "1",
                label: "已保存未发布",
              },
              {
                value: "2",
                label: "已发布",
              },
              {
                value: "3",
                label: "已下架",
              },
            ],
            Visible:false,
            visibleIndex:"",
            workVisibleShow:false,
            selectStatus:false,
            activeName:"first",
            subjectIdList:[],
            teacherList:[],
            teacherTotall:0,
            teacherParams:{
                status:"1",
                productId:"",
                pageNumber:"1",
                pageSize:"10",
            },
            visibelList:[],
            tableRadioInfo:{},
            classParams:{}
        }
    },
    mounted(){
      this.getProductList();
      this.getSubjectList();
    },
    methods:{
      setDispose(index) {
        this.teacherList[index].label = 2;
        this.teacherList[index].show = true;
      },
      singleWork(){
        this.infoParams.type="add"
        this.timeStatus=true;
      },
      CB_timeStatus(){
        this.timeStatus=false;
        this.findCourseItemFn();
      },
      CB_classStatus(){
        this.classStatus=false;
      },
      getMsgFormSon(data){
        this.Visible=false;
        this.$set(this.teacherList,this.visibleIndex,this.teacherList[this.visibleIndex])
      },
      callBackInfo(data){
        this.classParams=data;
        this.classStatus=true;
      },
      callBackTea(data){
        this.tableRadioInfo=data;
      },
        getProductList() {
          let data = {
            productStatus:
              this.listParams.productStatus == "0"
                ? null
                : this.listParams.productStatus,
            name: this.listParams.name,
            productType: this.listParams.productType,
            videoType: this.listParams.videoType == '0' ? '' : this.listParams.videoType,
            pageNumber: this.listParams.pageNumber,
            pageSize: this.listParams.pageSize,
            searchBeginDate:this.listParams.searchBeginDate,
            searchEndDate:this.listParams.searchEndDate
          };
          productList(data).then((res) => {
            this.tableList = res.rows;
            this.total = res.total;
          });
        },
      sureTeacherData(scope, index){
          let row = scope.row;
          if (row.teacherId == undefined) {
            this.msgWarn("请选择讲师！");
            return false;
          }
          if (row.classSinglePrice&&(row.classSinglePrice == undefined || row.classSinglePrice == "")) {
            this.msgWarn("请配置讲师单价！");
            return false;
          }
          let array={
            subjectId: row.subjectId,
            productId:this.infoParams.id,
            id: scope.row.id ? scope.row.id : null,
            teacherName: row.teacherRealname,
            classSinglePrice: row.classSinglePrice,
            label: row.label,
            courseLevel: row.courseLevel,
            classSinglePrice:row.classSinglePrice,
            hopeMinFee: row.hopeMinFee,
            hopeMaxFee: row.hopeMaxFee,
            teacherId: row.teacherId,
            flag: 1,
          }
          saveOneChooseTeacher(array).then((res) => {
            if (res.code == 0 && res.msg == "操作成功！") {
              this.msgSuccess("操作成功！");
              this.teacherListFn();
            } else {
              this.msgWarn(res.msg);
              return;
            }
          });
        },
        removeTeacher(scope){
          if (scope.row.id && !scope.row.groupId) {
            this.$confirm("是否删除该讲师?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                deleteOneChooseTeacher(scope.row.id).then((res) => {
                  if (res.code == 0) {
                    this.teacherListFn();
                    this.msgSuccess("删除成功！");
                  }
                });
              })
              .catch(() => {});
          }else{
            this.$confirm("是否删除该讲师?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.teacherList.splice(scope.$index, 1);
              })
              .catch(() => {});
          }
        },
        editClass(row){
          this.infoParams.type="edit";
          this.infoParams.classId=row.id;
          this.timeStatus=true;
        },
        removeClass(row){
          this.$confirm("是否删除该课程?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                deleteLiveBroadcastClass({id:row.id}).then((res) => {
                  if (res.code == 0) {
                    this.findCourseItemFn();
                    this.msgSuccess("删除成功！");
                  }
                });
              })
              .catch(() => {});
        },
        datePickerChange(){
            if (!this.timePickerValue) {
              this.listParams.searchBeginDate = null;
              this.listParams.searchEndDate = null;
              return;
            }
            this.listParams.searchBeginDate = this.timePickerValue[0];
            this.listParams.searchEndDate = this.timePickerValue[1];
        },
        handleSizeCourseChange(pageSize){
          this.listParams.pageSize = pageSize;
          this.getProductList();
        },
        handleCourseChange(currentPage){
          this.listParams.pageNumber = currentPage;
          this.getProductList();
        },
        goWorkClick(row){
          this.activeName='first';
          this.workVisibleShow=true;
          this.infoParams=row;
          this.teacherParams.productId=row.id;
          this.teacherListFn();
        },
        teacherListFn(){
            configteacherPlanList(this.teacherParams).then((res) => {
                this.teacherList = res.rows;
                this.teacherTotal = res.total;
            });
        },
        getSubjectList() {
            getSelectList({
                parentCode: subjectCode
            }).then(res => {
                if (res.code == 0) {
                this.subjectIdList = res.rows;
                }
            });
        },
        subjectClick(macroId){
            var data={
                "label":0,
                "productId": this.infoParams.id,
                "subjectId": macroId,
                "teacherId": "",
                "teacherName": ""
            }
            this.teacherList.unshift(data);
        },
        dealSubjectId(row){
            for(var i=0;i<this.subjectIdList.length;i++){
                if(this.subjectIdList[i].macroId==row.subjectId){
                    return this.subjectIdList[i].name;
                }
            }
        },
        //选老师
        choiceTeacherClick(index){
            this.teacherType=1;
            this.Visible=true;
            this.visibleIndex=index;
            this.visibelList=this.teacherList;
        },
        handleClick(tab, event){
            if (tab.index == 0) {
              this.teacherListFn();
            }
            else if (tab.index == 1) {
              this.findCourseItemFn();
            }
        },
        findCourseItemFn(){
          findCourseItem({productId:this.infoParams.id}).then((res) => {
                if(res.code==0){
                  this.courseList=res.rows.listProductItemTeachManager;
                }
              })
        },
        dealProductStatus(row) {
          let status = "";
          for (let i = 0, len = this.productStatusList.length; i < len; i++) {
            if (row.productStatus == this.productStatusList[i].value) {
              status = this.productStatusList[i].label;
            }
          }
          return status;
        },
    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .studentInfo{
    h2{
      font-size:16px;
      margin-top:20px;
    }
    ul{
      display: flex;
      flex-wrap:wrap;
      margin-top:10px;
      li{
        width:33.3%;
        padding:5px 0;
      }
    }
  }
  .infoRecord{
    display: flex;
    width:100%;
    span{
      width:25%;
      display: inline-block;
      padding:5px 0;
    }
  }
}
 .orderDetail{
    h2{
        font-size: 16px;
    }
    ul{
        display: flex;
        margin-top:20px;
        li{
            margin-right:40px;
            span:nth-child(1){
                color:#909399;
            }
            span:nth-child(2){
                color:#000000;
            }
        }
    }
    p{
        margin-top:10px;
        span:nth-child(1){
            color:#909399;
        }
        span:nth-child(2){
            color:#000000;
        }
    }
  }
  .subject_ul {
    width: 100%;
    overflow: visible;
    height: 55px;
    line-height: 55px;
  }
  .subject_ul li {
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    float: left;
  }
</style>